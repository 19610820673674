import { useSelector } from 'react-redux';
import './billingInfoForm.css';
import { RootState } from '../../store';

export default function BillingInfoForm(){
    const billing = useSelector((state: RootState) => state.sessionUserConfig.billing);

    return(
        <div id='container-info-form-config'>
            <h1>Billing</h1>
            
            <div id='container-information'>
                <div id='container-info'>
                    <dt>Contents Storage:</dt>
                    <div id='container-mb'>
                        <dd>{billing?.contentsStorage}</dd>
                        <dd>mb</dd>
                    </div>
                </div>
                <div id='container-info'>
                    <dt>Projects Storage:</dt>
                    <div id='container-mb'>
                        <dd>{billing?.projectsStorage}</dd>
                        <dd>mb</dd>
                    </div>
                </div>
                <div id='container-info'>
                    <dt>Total Storage:</dt>
                    <div id='container-mb'>
                        <dd>{billing?.megabytes}</dd>
                        <dd>mb</dd>
                    </div>
                </div>
                <div id='container-info'>
                    <dt>Per Megabytes:</dt>
                    <div id='container-mb'>
                        <dd>${billing?.perUnit}</dd>
                    </div>
                </div>
                <div id='container-total'>
                    <dt>Total:</dt>
                    <div id='container-mb'>
                        <dd>${billing?.perUnit}</dd>
                    </div>
                </div>
            </div>
        </div>
    )
}