import React, { useEffect } from 'react';
import './addRemoveUserInstruments.css';
import { FiX } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store';
import { deselectUserInstrument, selectUserInstrument, createUserInstrument, getInstruments, 
         getUserInstruments, patchUserInstrument } from '../../../../features/sessions/sessionProfileNavigation';
import { Instrument, UserInstrument } from '../../../../#interfaces/interfaces';

export default function AddRemoveUserInstruments({userNickname,onClose}:any){
    const instrumentsAvailable = useSelector((state: RootState) => state.sessionProfile.instrumentsAvailable);
    const userInstruments = useSelector((state: RootState) => state.sessionProfile.userInstruments);
    const currentUser = useSelector((state: RootState) => state.session.currentUser);
    const dispatch = useDispatch<AppDispatch>();
    const authHeaders = useSelector((state: RootState) => state.session.authHeaders);
    
    useEffect(() => {

        async function fetchData(){
            if (authHeaders){
                const response = await dispatch(getInstruments())
            }                                                        
        }

        fetchData();
    },[])

    async function handleUpdateCreateUserInstrument(instrumentId: number, selected: boolean) {

        if (authHeaders && userNickname && currentUser && currentUser.nickname && currentUser.id) {

            const userInstrument = userInstruments.find((userInstrument: UserInstrument) => 
                userInstrument.instrument.id === instrumentId
            );
             
            if (userInstrument?.selected === false) {
                const response = await dispatch(patchUserInstrument({
                    headers: authHeaders,
                    urlParams: {
                        userNickname: userNickname
                    },
                    requestBodyData: {
                        instrumentId: instrumentId,
                        selected: selected
                    }
                }));
            } else if (userInstrument?.id === undefined || !userInstrument?.selected){
                const response = await dispatch(createUserInstrument({
                    headers: authHeaders,
                    urlParams: {
                        currentUserNickname: userNickname
                    },
                    requestBodyData: {
                                        userId: currentUser.id,
                                        instrumentId: instrumentId
                    }
                }));
            }
        }
    }    

    async function handleUpdateUserInstrument(instrumentId: number, selected: boolean){
        if (authHeaders && userNickname && currentUser && currentUser.nickname && currentUser.id) {
    
            const userInstrument = userInstruments.find((userInstrument: UserInstrument) => 
                userInstrument.instrument.id === instrumentId
            );
    
            if (userInstrument?.selected === true) {

                const response = await dispatch(patchUserInstrument({
                    headers: authHeaders,
                    urlParams: {
                        userNickname: userNickname
                    },
                    requestBodyData: {
                        instrumentId: instrumentId,
                        selected: selected
                    }
                }));
            } 
        }
    }

    return(
            <>
                <div id='container-add-remove-user-instruments-background-modal'>
                    <div id='container-screen-transparent-add-remove-user-instruments-modal'>
                        <div id='container-add-remove-user-instruments-modal'>
                            <div id='container-top-add-remove-user-instruments-modal'>
                                <h2>Genres Preferences</h2>
                                <button onClick={ () => onClose(false) }>
                                    <FiX size={25} color="#FFF"/>
                                </button>
                            </div>
                            <div id='container-list-add-remove-user-instruments-modal'>
                                <ul>
                                    {instrumentsAvailable && instrumentsAvailable.map((instrument: Instrument, index) => {
                                        const isSelected = userInstruments.some(userInstrument => userInstrument.instrument.name === instrument.name 
                                                                                                  && userInstrument.selected === true);
                                        return (
                                        <React.Fragment key={index}>
                                            {isSelected ? (
                                            <li id='li-user-instruments-already-selected'
                                                key={`${index}-selected`} 
                                                onClick={() => handleUpdateUserInstrument(instrument.id, false)}>
                                                <h3>
                                                    {instrument.name}
                                                </h3>
                                            </li>
                                            ) : (
                                            <li id='li-instruments-available'
                                                key={`${index}-available`} 
                                                onClick={() =>  handleUpdateCreateUserInstrument(instrument.id, true)}>
                                                <h3>
                                                    +{instrument.name}
                                                </h3>
                                            </li>
                                            )}
                                        </React.Fragment>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    )
}