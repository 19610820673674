import React, { useRef } from 'react';
import { useEffect, useState } from 'react';
import './sideBar.css';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { useNavigate } from 'react-router-dom';
import { authenticateUserFromStorage } from '../../features/sessions/sessionSlice';
import * as REQUEST_REQUIREMENTS from '../../api/requestRequirements';

function SideBar({close}: any){
    const currentUser = useSelector((state: RootState) => state.session.currentUser);
    const userValidated = useSelector((state: RootState) => state.session.loggedIn);
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const [PRIVATE_ROUTES,setPrivateRoutes] = useState<REQUEST_REQUIREMENTS.EndPoints>()
    const sideBarRef = useRef<HTMLDivElement>(null);

    useEffect(()=>{
        async function authUserOnRoute() {
            if (!userValidated) {
                await dispatch(authenticateUserFromStorage());
            }
        }

        //const [PRIVATE_ROUTES,setPrivateRoutes]= useState<REQUEST_REQUIREMENTS.EndPoints>()

        const routeParams={
                            userNickname: currentUser?.nickname
        }
  
        const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: routeParams});

        authUserOnRoute();
        setPrivateRoutes(PRIVATE_ROUTES);
    },[])

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                sideBarRef.current &&
                !sideBarRef.current.contains(event.target as Node)
            ) {
                close(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div id='container-side-bar-overlay' ref={sideBarRef}>
            <nav  id='side-bar-container'>
                <ul>
                    <li onClick={()=>{ if (PRIVATE_ROUTES && PRIVATE_ROUTES.USER){
                                        close();
                                        navigate(PRIVATE_ROUTES.USER)
                                       }  
                                    
                            }}>Profile</li>
                    <li onClick={() => {close();
                                        navigate(REQUEST_REQUIREMENTS.SIGNIN_ENDPOINT);
                                       }}>My Contents</li>
                    
                    <li onClick={()=>{ if (PRIVATE_ROUTES && PRIVATE_ROUTES.USER_PROJECTS) {
                                            close(); 
                                            navigate(PRIVATE_ROUTES.USER_PROJECTS);
                                       }
                            }}>My Projects</li>
                    <li onClick={()=>{ if (PRIVATE_ROUTES && PRIVATE_ROUTES.MEMBER_GROUPS) {
                                            close(); 
                                            navigate(PRIVATE_ROUTES.MEMBER_GROUPS);
                                       }
                            }}>Groups</li>
                    
                </ul>
            </nav>
        </div>
    )
}

export default SideBar;