import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createUserDialogueWithUserNickname, getUserDialoguesByPage, getUserDialoguesMessagesByPage } from "../../api/sessionAPI";
import { AuthHeaders, Dialogue, DialogueMember } from "../../#interfaces/interfaces";

export interface GetUserDialogueMessagesRequestParams {
  authHeaders: AuthHeaders;
  urlParams: GetUserDialoguesMessagesUrlParams;
}

export interface GetUserDialoguesMessagesUrlParams {
  dialogueId: number;
  page: number;
}

export interface GetUserDialoguesRequestParams{
  authHeaders: AuthHeaders;
  urlParams: GetUserDialoguesUrlParams;
}

export interface GetUserDialoguesUrlParams{
  page: number;
}

export interface PostUserDialogueRequestParams{
  authHeaders: AuthHeaders;
  urlParams: PostUserDialogueUrlParams;
  body: PostUserDialogueBody;
}

export interface PostUserDialogueUrlParams{
  userNickname: string;
  currentUserNickname: string;
}

export interface PostUserDialogueBody{
  message: string;
}

export interface SelectDeselectDialogue{
  nickname: string;
}

export interface CreateDialogue{
  nickname: string;
  message?: string;
}

export interface OpenCloseDialogue{
  nickname: string;
}

export interface GetUserNotificationsUrlParams{
    currentUserId?: number;
}

interface UserDialogues {
    selectedDialogues: string[];
    userDialogues: Dialogue[];
    openedDialogueBox: Dialogue[];
    loadingNewSentMessage: boolean;
    loadingMoreDialogueMessages: boolean;
    lastMessagesPage: boolean;
    loadingMoreDialogues: boolean;
}

const initialState: UserDialogues = {
    selectedDialogues: JSON.parse(localStorage.getItem('selectedDialogues') || '[]'),
    userDialogues: [],
    openedDialogueBox: [],
    loadingNewSentMessage: false,
    loadingMoreDialogueMessages: false,
    lastMessagesPage: false,
    loadingMoreDialogues: false
}

export const getDialogueMessages = createAsyncThunk(
  'sessionDialogues/getDialogueMessages',
  async (payload: GetUserDialogueMessagesRequestParams, {rejectWithValue}) => {
      const response = await getUserDialoguesMessagesByPage(
          payload.authHeaders,
          payload.urlParams
      )
      if (response.status >= 200 && response.status < 300) {
          return response.data 
      } else {
        return rejectWithValue(response.data)
      }
  }
)

export const getUserDialogues = createAsyncThunk(
    'sessionDialogues/getUserDialogues',
    async (payload: GetUserDialoguesRequestParams, {rejectWithValue}) => {
        const response = await getUserDialoguesByPage(
            payload.authHeaders,
            payload.urlParams
        )
        if (response.status >= 200 && response.status < 300) {
            return response.data 
        } else {
          return rejectWithValue(response.data)
        }
    }
)

export const createUserDialogue = createAsyncThunk(
  'sessionDialogues/createUserDialogue',
  async (payload: PostUserDialogueRequestParams, {rejectWithValue}) => {
      const response = await createUserDialogueWithUserNickname(
          payload.authHeaders,
          payload.urlParams,
          payload.body
      )
      if (response.status >= 200 && response.status < 300) {
          return response.data 
      } else {
        return rejectWithValue(response.data)
      }
  }
)

const dialoguesSlice = createSlice({
    name: 'sessionDialogues',
    initialState,
    reducers: {
      selectDialogue: (state, action: PayloadAction<SelectDeselectDialogue>) => {
        state.selectedDialogues = [...state.selectedDialogues, action.payload.nickname];
        localStorage.setItem('selectedDialogues', JSON.stringify(state.selectedDialogues));
      },
      deselectDialogue: (state, action: PayloadAction<SelectDeselectDialogue>) => {
        state.selectedDialogues = state.selectedDialogues.filter(
          nickname => nickname !== action.payload.nickname
        );
        localStorage.setItem('selectedDialogues', JSON.stringify(state.selectedDialogues));
      },
      openCloseDialogueBox: (state, action: PayloadAction<OpenCloseDialogue>) => {
      
        const matchingDialogue = state.userDialogues.find(userDialogue => 
          userDialogue.dialogueMembers?.some(member => member.user?.nickname === action.payload.nickname)
        );

        const matchingOpenedDialogue = state.openedDialogueBox.find(userDialogue => 
          userDialogue.dialogueMembers?.some(member => member.user?.nickname === action.payload.nickname)
        );
      
        if (matchingDialogue && !state.openedDialogueBox.includes(matchingDialogue) && !matchingOpenedDialogue) {
          state.openedDialogueBox = [...state.openedDialogueBox, matchingDialogue];
        } else if (matchingOpenedDialogue) {

          state.openedDialogueBox = state.openedDialogueBox.filter(openedDialogue => 
            !openedDialogue.dialogueMembers?.some(dialogueMember => dialogueMember.user?.nickname === action.payload.nickname)
          );
      
        } else {
          state.openedDialogueBox = [...state.openedDialogueBox, {
            dialogueMessages: [],
            dialogueMembers: [
                {
                    user: {
                        nickname: action.payload.nickname
                    }
                }
            ]
        }];
        }
      },
      closeAllDialogueBoxes: (state) => {
        state.selectedDialogues = [];
        localStorage.removeItem('selectedDialogues');
      }
    },
    extraReducers: (builder) => {
      builder
      .addCase(getUserDialogues.pending, (state, action: any) => {
        state.loadingMoreDialogues = true;
      })
      .addCase(getUserDialogues.fulfilled, (state, action: any) => {
        state.userDialogues = [...state.userDialogues, ...convertKeysToCamelCaseAndSortByUpdatedAt(action.payload)];
        state.loadingMoreDialogues = false;
      })
      .addCase(getUserDialogues.rejected, (state) => {
        state.loadingNewSentMessage=true;
        state.loadingMoreDialogues = false;
      })
      .addCase(createUserDialogue.pending, (state, action: any) => {
        const convertedDialogue = convertKeysToCamelCaseAndSortByUpdatedAt(action);

        const { userNickname, currentUserNickname } = action.meta.arg.urlParams;

        const index = state.userDialogues.findIndex(dialogue => 
          dialogue.dialogueMembers?.every(member => 
            member.user?.nickname === userNickname || member.user?.nickname === currentUserNickname
          )
        );
        const newMessage = {
          sender: {nickname: convertedDialogue.meta.arg.urlParams.currentUserNickname},
          recipient: {nickname: convertedDialogue.meta.arg.urlParams.userNickname},
          message: convertedDialogue.meta.arg.body.message,
          sent: false
        };

        if (index !== -1) {

          state.userDialogues[index].dialogueMessages?.push(newMessage);
          state.loadingNewSentMessage=false;
        } else {

          state.userDialogues.push({
            dialogueMembers: [
                { user: { nickname: currentUserNickname } },
                { user: { nickname: userNickname } }
            ],
            dialogueMessages: [newMessage]
        });
          state.loadingNewSentMessage=false;
        }

      })
      .addCase(createUserDialogue.fulfilled, (state, action: any) => {
        const convertedDialogue = convertKeysToCamelCaseAndSortByUpdatedAt(action.payload);
      
        const dialogueIndex = state.userDialogues.findIndex(dialogue =>
          dialogue.dialogueMembers?.length === convertedDialogue.dialogueMembers.length &&
          dialogue.dialogueMembers?.every(dialogueMember =>
            convertedDialogue.dialogueMembers.some(
              (newDialogueMember: DialogueMember) => newDialogueMember.user?.nickname === dialogueMember.user?.nickname
            )
          )
        );
      
        if (dialogueIndex !== -1) {
          state.userDialogues[dialogueIndex].dialogueMessages = state.userDialogues[dialogueIndex].dialogueMessages?.filter(
            (dialogueMessage: any) => dialogueMessage.createdAt
          );
          
          state.userDialogues[dialogueIndex].dialogueMessages?.push(convertedDialogue.dialogueMessages[0]);
        } else {
          console.warn(`Dialogue with matching dialogueMembers not found`);
        }
      })      
      .addCase(createUserDialogue.rejected, (state) => {
        console.log("getUserDialogues.rejected")
      })
      .addCase(getDialogueMessages.pending, (state, action: any) => {
        state.loadingMoreDialogueMessages = true;
      })
      .addCase(getDialogueMessages.fulfilled, (state, action: any) => {
        const payloadConverted = convertKeysToCamelCase(action.payload)

        const dialogueIndex = state.userDialogues.findIndex(dialogue => 
          dialogue.id === payloadConverted.id);

        if (dialogueIndex !== undefined) {

          state.userDialogues[dialogueIndex].dialogueMessages = [...payloadConverted.dialogueMessages, ...state.userDialogues[dialogueIndex].dialogueMessages ?? []];
        
        } 
        state.loadingMoreDialogueMessages = false;

        state.lastMessagesPage = payloadConverted.dialogueMessages.length < 5 ? true : false
      })
      .addCase(getDialogueMessages.rejected, (state) => {
        state.loadingMoreDialogueMessages = false;
      })
      }
    })

export const { selectDialogue, deselectDialogue, 
               openCloseDialogueBox, closeAllDialogueBoxes } = dialoguesSlice.actions;

export const dialoguesSliceReducers = dialoguesSlice.reducer;
export const dialoguesSliceActions = dialoguesSlice.actions;

export function convertKeysToCamelCaseAndSortByUpdatedAt(obj: any): any {
  function convertKeysToCamelCase(inputObj: any): any {
    if (typeof inputObj === 'object' && inputObj !== null) {
      if (Array.isArray(inputObj)) {
        return inputObj.map(item => convertKeysToCamelCase(item));
      } else {
        if (inputObj.constructor === Object) {
          const newObj: { [key: string]: any } = {};
          for (const key in inputObj) {
            if (Object.prototype.hasOwnProperty.call(inputObj, key)) {
              const camelCaseKey = key.replace(/_([a-z])/g, (match, letter) =>
                letter.toUpperCase()
              );
              newObj[camelCaseKey] = convertKeysToCamelCase(inputObj[key]);
            }
          }
          return newObj;
        }
      }
    }
    return inputObj;
  }

  const camelCasedObj = convertKeysToCamelCase(obj);

  if (Array.isArray(camelCasedObj)) {
    return camelCasedObj.sort((a, b) => {
      const dateA = new Date(a.updatedAt);
      const dateB = new Date(b.updatedAt);
      return dateB.getTime() - dateA.getTime();
    });
  } else {
    return camelCasedObj;
  }
}

export function convertKeysToCamelCase(obj: any): any {
  if (typeof obj === 'object' && obj !== null) {
    if (Array.isArray(obj)) {
      return obj.map(item => convertKeysToCamelCase(item));
    } else if (obj.constructor === Object) {
      const newObj: { [key: string]: any } = {};
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const camelCaseKey = key.replace(/_([a-z])/g, (_, letter) =>
            letter.toUpperCase()
          );
          newObj[camelCaseKey] = convertKeysToCamelCase(obj[key]);
        }
      }
      return newObj;
    }
  }
  return obj;
}