import { useDispatch, useSelector } from "react-redux";
import { Dialogue, DialogueMessage } from "../../../#interfaces/interfaces";
import './messageBox.css';
import { AppDispatch, RootState } from "../../../store";
import SendMessageIcon from '../../../assets/send-message-icon-white-64.png'
import { useRef, useState, useEffect } from "react";
import { format } from "date-fns";
import { createUserDialogue, getDialogueMessages } from "../../../features/sessions/sessionDialogues";
import { CircularProgress } from "@mui/material";

export default function MessageBox({userNickname}: {userNickname: string}) {
    const authHeaders = useSelector((state: RootState) => state.session.authHeaders);
    const currentUser = useSelector((state: RootState) => state.session.currentUser);
    const matchingDialogue = useSelector((state: RootState) => 
        state.sessionDialogues.userDialogues.find(dialogue => 
          dialogue.dialogueMembers?.some(member => member.user?.nickname === userNickname) && 
          dialogue.dialogueMembers.some(member => member.user?.nickname === currentUser?.nickname)
        )
    );
    const [message, setMessage] = useState('');
    const textAreaMessage = useRef<HTMLTextAreaElement | null>(null);
    const dispatch = useDispatch<AppDispatch>();
    const messageListEndRef = useRef<HTMLLIElement | null>(null);
    const ulMessagesRef = useRef<HTMLUListElement | null>(null);
    const [messagesPage, setMessagesPage] = useState(1);
    const loadingMoreDialogueMessages = useSelector((state: RootState) => state.sessionDialogues.loadingMoreDialogueMessages);
    const lastMessagesPage = useSelector((state: RootState) => state.sessionDialogues.lastMessagesPage);

    useEffect(() => {
        const element = ulMessagesRef.current;
    
        if (element && messagesPage === 1) {
            element.scrollTop = element.scrollHeight;
        }
    }, [matchingDialogue?.dialogueMessages]);

    useEffect(() => {
        const element = ulMessagesRef.current;
    
        if (element && !lastMessagesPage) {
            element.scrollTop = element.scrollHeight * 0.1;
        }

    }, [loadingMoreDialogueMessages]);

    function handleFormatDate(date: any) {
        const originalDate = new Date(date);
        const formattedDate = format(originalDate, 'dd MMM yyyy HH:mm:ss');
        return formattedDate;
    }
    
    async function handleSendMessage(userNickname: string, message: string) {
      

        if (authHeaders && currentUser?.nickname) {
            const response = await dispatch(createUserDialogue({
                authHeaders: authHeaders,
                urlParams: {
                    userNickname: userNickname,
                    currentUserNickname: currentUser.nickname
                },
                body: {
                    message: message
                }
            })
        
        );
        
            if (ulMessagesRef?.current) {
                ulMessagesRef.current.scrollTop = ulMessagesRef.current.scrollHeight;
            }

            if (response.meta.requestStatus === 'fulfilled'){
                setMessage('');
            }
        }
    }

    const fetchMessages = async () => {
        try {
            if (authHeaders && matchingDialogue && matchingDialogue.id ) {
                const response = await dispatch(getDialogueMessages({
                    authHeaders: authHeaders,
                    urlParams: {
                        dialogueId: matchingDialogue.id,
                        page: messagesPage
                    }
                }));
            }

        } catch (error) {
            console.error("Error fetching messages:", error);
        }
    };

    useEffect(() => {
        
        if (messagesPage > 1){
            fetchMessages();
        }
        
    }, [messagesPage]);


    useEffect(() => {
        const handleScroll = () => {
            const element = ulMessagesRef.current;
    
            if (!element) {
                return;
            }
    
            const { scrollTop } = element;
    
            if (scrollTop === 0) {
                setMessagesPage((prevPage) => prevPage + 1);
            }
        };
    
        const element = ulMessagesRef.current;
        if (element) {
            element.addEventListener('scroll', handleScroll);
    
            return () => {
                element.removeEventListener('scroll', handleScroll);
            };
        }
    }, []);
    

    function handleKeyDown(event: React.KeyboardEvent<HTMLTextAreaElement>) {
        if (event.key === 'Enter' && !event.shiftKey) {
            if (message.length === 0) {
                event.preventDefault();
                return; 
            }
            event.preventDefault();
            handleSendMessage(userNickname, message);
        }
    }


    return (
        <div id='container-message-box'>
            <div id='container-ul-messages-box-components'>
                <ul id="ul-messages" ref={ulMessagesRef}>
                    <div id='container-loading'>
                        { loadingMoreDialogueMessages ? 
                            <CircularProgress color='secondary' sx={{ color: 'brown' }} /> : null
                        }
                    </div>
                    {matchingDialogue?.dialogueMessages?.map((dialogueMessage: DialogueMessage, index: number) => (
                        <div key={index} id={`container-message-${currentUser?.nickname === dialogueMessage.sender?.nickname ? 'sender' : 'recipient'}`}>
                            
                            <li id={`li-${dialogueMessage.sent === false || undefined ? 'did-not-send' : 'sent'}`}>
                                <p>{dialogueMessage.message}</p>
                                <div id='container-date-hour'>
                                    <span>{dialogueMessage.createdAt ? handleFormatDate(dialogueMessage.createdAt) : null}</span>
                                </div>
                            </li>
                        </div>
                    ))}
                    <li ref={messageListEndRef} />
                </ul>
                <div id='container-field-button'>
                    <textarea
                        id='message-message-box'
                        name="input-message-message-box"
                        ref={textAreaMessage}
                        value={message}
                        maxLength={248}
                        onChange={(event) => setMessage(event.target.value)}
                        onKeyDown={handleKeyDown}
                    />
                    {message.length > 0 ?
                    
                        <button onClick={() => handleSendMessage(userNickname, message)}>
                            <img src={SendMessageIcon} />
                        </button>
                    : 
                    
                    null
                    }
                </div>
            </div>
        </div>
    );

}
