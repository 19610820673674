import { useEffect, useState } from 'react';
import { Dialogue, DialogueMessage } from '../../../#interfaces/interfaces';
import MessageBox from './MessageBox';
import './messagesBoxes.css';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store';
import CloseIcon from '../../../assets/close-icon-white-64.png';
import { deselectDialogue, openCloseDialogueBox, selectDialogue } from '../../../features/sessions/sessionDialogues';

export default function MessagesBoxes(){
    const selectedDialogues = useSelector((state: RootState) => state.sessionDialogues.selectedDialogues);
    const dispatch = useDispatch<AppDispatch>();
    const openedDialogues = useSelector((state: RootState) => state.sessionDialogues.openedDialogueBox);
    // const [showMessageBoxes, setShowMessageBoxes] = useState<boolean>(false);

    // useEffect(()=> {

    //     if (selectedDialogues.length === 0){
    //         setShowMessageBoxes(false)
    //     } else if (selectedDialogues.length > 0){
    //         setShowMessageBoxes(true)
    //     }
    //     console.log("selectedDialogues >> ", selectedDialogues)

    // },[selectedDialogues])

    async function handleOpenDialogue(userNickname: string){
        dispatch(openCloseDialogueBox({nickname: userNickname}))
    }

    async function handleDeselectDialogue(userDialogue: string) {
        
        try {
            
            if (!selectedDialogues.includes(userDialogue)) {
                return dispatch(selectDialogue({ nickname: userDialogue }));
            } else {
                return dispatch(deselectDialogue({ nickname: userDialogue }));
            }

        } catch (error) {
            console.error(error);
        }
    }

    return(
            <div id='container-messages-boxes'>
                <ul>
                    {selectedDialogues.length > 0 && selectedDialogues.map((userNickname: string, index: number) => (

                            <li key={index}>
                                {openedDialogues.some(userDialogue => 
                                    userDialogue.dialogueMembers?.some(member => member.user?.nickname === userNickname)
                                ) && (
                                    <MessageBox userNickname={userNickname} />
                                )}
                                <div id='container-user-name'  onClick={() => {handleOpenDialogue(userNickname)}}>
                                    <h3>{userNickname}</h3>
                                    <button  onClick={() => handleDeselectDialogue(userNickname)}>
                                        <img src={CloseIcon}/>
                                    </button>
                                </div>
                            </li>
                    ))
                    }
                </ul>
            </div>  
    );
}
