import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AuthHeaders, GetGroupRouteParams, MusicGenre, UserMoreInfo } from "../../../#interfaces/interfaces";
import { deleteGroupWithGroupId, getGroupWithNicknameAndGroupId, getMusicGenres, getRouteUserGroups, getUserGenericConfigurations, patchAvatar, patchGerneralConfig, patchPrivacityConfig, postCreateGroup } from "../../../api/sessionAPI";


interface GetGroupData{
  authHeaders: AuthHeaders;
  getGroupRouteParams: GetGroupRouteParams;
}

export interface User {
  email?: string;
  provider?: string;
  uid?: string;
  id?: number;
  allowPasswordChange?: boolean;
  name?: string;
  image?: string;
  nickname?: string;
  role?: string;
  confirmed?: string;
  updatedAt?: string;
  createdAt?: string;
  refreshToken?: string;
  userMoreInfo?: UserMoreInfo;
}


export interface GroupMusicalGenre {
  createdAt?: string;
  updatedAt?: string;
  groupId?: number;
  id?: number;
  musicGenreId?: number;
  musicGenre: MusicGenre;
}

export interface Group {
    id?: number;
    description?: string;
    privacity?: number;
    nMembers?: number;
    createdAt?: string;
    updatedAt?: string;
    name?: string;
    groupMusicalGenres?: GroupMusicalGenre[];
}

const initialState: Group = {
    id: undefined,
    description: undefined,
    privacity: undefined,
    nMembers: undefined,
    createdAt: undefined,
    updatedAt: undefined,
    name: undefined,
    groupMusicalGenres: []
}


export const getGroup = createAsyncThunk(
  'groupSlice/getGroup',
  async (payload: GetGroupData, {rejectWithValue}) => {
      const response = await getGroupWithNicknameAndGroupId(
          payload.authHeaders,
          payload.getGroupRouteParams
      )

      if (response.status >= 200 && response.status < 300) {
          return response.data 
      } else {
        return rejectWithValue(response.data)
      }
  }
)

const groupSlice = createSlice({
    name: 'groupSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
      .addCase(getGroup.fulfilled, (state, action: any) => {
        
        const responseCamelCase = convertKeysToCamelCase(action.payload);

        state.id = responseCamelCase.id;
        state.description = responseCamelCase.description;
        state.privacity = responseCamelCase.privacity;
        state.nMembers = responseCamelCase.nMembers;
        state.createdAt = responseCamelCase.createdAt;
        state.updatedAt = responseCamelCase.updatedAt;
        state.name = responseCamelCase.name;
        state.groupMusicalGenres = responseCamelCase.groupMusicalGenres;
      })
    }
})



export const groupSliceReducers = groupSlice.reducer
export const groupSliceActions = groupSlice.actions

export function sortAndMergeGroups(...lists: any[][]): any[] {

    const mergedList = ([] as any[]).concat(...lists);

    const compareDates = (a: string, b: string) => {
    const dateA = new Date(a);
    const dateB = new Date(b);
    return  dateB.getTime() - dateA.getTime();
    };

    mergedList.sort((a, b) => compareDates(a.created_at, b.created_at));

    const mergedListConverted = convertKeysToCamelCase(mergedList);
    return mergedListConverted;
}

export function convertKeysToCamelCaseAndSortByUpdatedAt(obj: any): any {
  function convertKeysToCamelCase(inputObj: any): any {
    if (typeof inputObj === 'object' && inputObj !== null) {
      if (Array.isArray(inputObj)) {
        return inputObj.map(item => convertKeysToCamelCase(item));
      } else {
        if (inputObj.constructor === Object) {
          const newObj: { [key: string]: any } = {};
          for (const key in inputObj) {
            if (Object.prototype.hasOwnProperty.call(inputObj, key)) {
              const camelCaseKey = key.replace(/_([a-z])/g, (match, letter) =>
                letter.toUpperCase()
              );
              newObj[camelCaseKey] = convertKeysToCamelCase(inputObj[key]);
            }
          }
          return newObj;
        }
      }
    }
    return inputObj;
  }

const camelCasedObj = convertKeysToCamelCase(obj);

  if (Array.isArray(camelCasedObj)) {
    return camelCasedObj.sort((a, b) => {
      const dateA = new Date(a.updatedAt);
      const dateB = new Date(b.updatedAt);
      return dateB.getTime() - dateA.getTime();
    });
  } else {
    return camelCasedObj;
  }
}

export function convertKeysToCamelCase(obj: any): any {
  if (typeof obj === 'object' && obj !== null) {
    if (Array.isArray(obj)) {
      return obj.map(item => convertKeysToCamelCase(item));
    } else if (obj.constructor === Object) {
      const newObj: { [key: string]: any } = {};
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const camelCaseKey = key.replace(/_([a-z])/g, (_, letter) =>
            letter.toUpperCase()
          );
          newObj[camelCaseKey] = convertKeysToCamelCase(obj[key]);
        }
      }
      return newObj;
    }
  }
  return obj;
}