import { io } from "socket.io-client";

const socket = (usertoken) => {
  return new io(process.env.REACT_APP_SERVER_URL, {
    autoConnect: false,
    withCredentials: true,
    auth: {
      token: usertoken,
    },
  });

};

export default socket;
