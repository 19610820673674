import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSearchBar, getUserNotificationsByPage } from "../../api/sessionAPI";
import { AuthHeaders, Notification } from "../../#interfaces/interfaces";
export interface GetUserNotificationsRequestParams{
    authHeaders: AuthHeaders;
    urlParams: {
      page: number;
    };
}

export interface GetUserNotificationsUrlParams{
    page: number;
}

export interface GetUserNotificationsUrlParams{
    currentUserId?: number;
}

interface UserNotifications {
    notifications: Notification[];
    loadingMoreNotifications: boolean;
}

const initialState: UserNotifications = {
    notifications: [],
    loadingMoreNotifications: false
}

export const getUserNotifications = createAsyncThunk(
    'sessionNotifications/getUserNotifications',
    async (payload: GetUserNotificationsRequestParams, {rejectWithValue}) => {
        const response = await getUserNotificationsByPage(
            payload.authHeaders,
            payload.urlParams
        )
        if (response.status >= 200 && response.status < 300) {
            return response.data 
        } else {
          return rejectWithValue(response.data)
        }
    }
)

const notificationsSlice = createSlice({
    name: 'sessionNotifications',
    initialState,
    reducers: {
      incrementNotifications: (state, action: any) =>{
        state.notifications = [convertKeysToCamelCase(action.payload),...state.notifications];

      }
    },
    extraReducers: (builder) => {
      builder
      .addCase(getUserNotifications.pending, (state, action: any) => {

        if (action.meta.arg.urlParams.page === 1){
          state.notifications = [];
        }

        state.loadingMoreNotifications = true;
      })
      .addCase(getUserNotifications.fulfilled, (state, action: any) => {

        if (action.meta.arg.urlParams.page > 1 ){
          state.notifications = [...convertKeysToCamelCaseAndSortByUpdatedAt(action.payload),...state.notifications]
        } else {
          state.notifications = convertKeysToCamelCaseAndSortByUpdatedAt(action.payload);
        }
        state.loadingMoreNotifications = false;
      })
      .addCase(getUserNotifications.rejected, (state) => {
        state.loadingMoreNotifications = false;
      })
      }
    })

export const notificationsSliceReducers = notificationsSlice.reducer;
export const notificationsSliceActions = notificationsSlice.actions;

export function convertKeysToCamelCaseAndSortByUpdatedAt(obj: any): any {
  function convertKeysToCamelCase(inputObj: any): any {
    if (typeof inputObj === 'object' && inputObj !== null) {
      if (Array.isArray(inputObj)) {
        return inputObj.map(item => convertKeysToCamelCase(item));
      } else {
        if (inputObj.constructor === Object) {
          const newObj: { [key: string]: any } = {};
          for (const key in inputObj) {
            if (Object.prototype.hasOwnProperty.call(inputObj, key)) {
              const camelCaseKey = key.replace(/_([a-z])/g, (match, letter) =>
                letter.toUpperCase()
              );
              newObj[camelCaseKey] = convertKeysToCamelCase(inputObj[key]);
            }
          }
          return newObj;
        }
      }
    }
    return inputObj;
  }

  const camelCasedObj = convertKeysToCamelCase(obj);

  if (Array.isArray(camelCasedObj)) {
    return camelCasedObj.sort((a, b) => {
      const dateA = new Date(a.updatedAt);
      const dateB = new Date(b.updatedAt);
      return dateB.getTime() - dateA.getTime();
    });
  } else {
    return camelCasedObj;
  }
}

export function convertKeysToCamelCase(obj: any): any {
  if (typeof obj === 'object' && obj !== null) {
    if (Array.isArray(obj)) {
      return obj.map(item => convertKeysToCamelCase(item));
    } else if (obj.constructor === Object) {
      const newObj: { [key: string]: any } = {};
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const camelCaseKey = key.replace(/_([a-z])/g, (_, letter) =>
            letter.toUpperCase()
          );
          newObj[camelCaseKey] = convertKeysToCamelCase(obj[key]);
        }
      }
      return newObj;
    }
  }
  return obj;
}