import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AuthHeaders, Billing, UserMoreInfo, UserPrivacityConfig, UserPropertyConfig } from "../../../#interfaces/interfaces";
import { getUserGenericConfigurations, patchAvatar, patchGerneralConfig, patchPrivacityConfig } from "../../../api/sessionAPI";

interface PatchAvatarForm {
    authHeaders: AuthHeaders;
    userNickname: string;
    patchBody: File;
  }

interface PatchGeneralConfigData {
  authHeaders: AuthHeaders;
  userNickname: string;
  patchBody: PatchConfigBody;
}

interface PatchPrivacityConfigData {
  authHeaders: AuthHeaders;
  userNickname: string;
  patchBody: PatchPrivacityConfigBody;
}

export interface PatchPrivacityConfigBody {
  contents: PropertyBody;
  projects: PropertyBody;
}

interface PropertyBody {
  id?: number;
  userPrivacityId?: number;
}

interface GetPrivacityConfigData {
  authHeaders: AuthHeaders;
  userNickname: string;
}

export interface PatchConfigBody {
  name: string,
  lastName: string,
  nickname: string,
  email: string,
  phoneNumber: string,
  website: string,
  city: string,
  state: string,
  country: string,
  about: string
}

interface PatchPrivacityConfigForm {
  authHeaders: AuthHeaders;
  userNickname: string;
  patchBody: UserPrivacityConfigBody;
}

export interface UserPrivacityConfigBody{
  user_id?: number,
  user_privacity_id?: number,
  user_property_type_id?: number
}


interface GeneralConfig {
    userMoreInfo: UserMoreInfo;
    userPrivacityConfigurations: UserPrivacityConfig;
    billing?: Billing;
    loading?: boolean;
    errors: string[];
}

const initialState: GeneralConfig = {
    userMoreInfo: {  
        name: '',
        nickname: '',
        email: '',
        phoneNumber: '',
        website: '',
        lastName: '',
        city: '',
        state: '',
        country: '',
        about: '',
        avatarUrl: '',
        cover: undefined
    },
    userPrivacityConfigurations: {
      contents: {},
      projects: {}
    },
    billing: undefined,
    errors: [],
    loading: false
}


export const updateAvatar = createAsyncThunk(
    'sessionProfile/updateAvatar',
    async (payload: PatchAvatarForm, {rejectWithValue}) => {
        const response = await patchAvatar(
            payload.authHeaders,
            payload.userNickname,
            payload.patchBody
        )
        if (response.status >= 200 && response.status < 300) {
            return response.data 
        } else {
          return rejectWithValue(response.data)
        }
    }
  )

  export const updateGeneralConfig = createAsyncThunk(
    'userConfigSlice/updateGeneralConfig',
    async (payload: PatchGeneralConfigData, {rejectWithValue}) => {
        const response = await patchGerneralConfig(
            payload.authHeaders,
            payload.userNickname,
            payload.patchBody
        )

        if (response.status >= 200 && response.status < 300) {
            return response.data 
        } else {
          return rejectWithValue(response.data)
        }
    }
  )



  export const getUserGenericConfig = createAsyncThunk(
    'userConfigSlice/getUserGenericConfig',
    async (payload: GetPrivacityConfigData, {rejectWithValue}) => {
        const response = await getUserGenericConfigurations(
            payload.authHeaders,
            payload.userNickname
        )
        if (response.status >= 200 && response.status < 300) {
            return response.data 
        } else {
          return rejectWithValue(response.data)
        }
    }
  )

const userConfigSlice = createSlice({
    name: 'userConfigSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
      .addCase(updateGeneralConfig.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateGeneralConfig.fulfilled, (state, action: any) => {
        const payloadConverted = convertKeysToCamelCase(action.payload)
        state.userMoreInfo = payloadConverted.userMoreInfo
        state.loading = false;
      })
      .addCase(updateGeneralConfig.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getUserGenericConfig.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserGenericConfig.fulfilled, (state, action: any) => {
        state.loading = false;
        const responseCamelCase = convertKeysToCamelCase(action.payload) 
        state.userMoreInfo = responseCamelCase.userMoreInfo;

        state.billing = responseCamelCase.billing

        const responsePrivacityConfiguration = responseCamelCase.userPrivacityConfiguration;
        const contents = responsePrivacityConfiguration.find((obj: UserPropertyConfig) => obj.userPropertyTypeId === 1);
        const projects = responsePrivacityConfiguration.find((obj: UserPropertyConfig) => obj.userPropertyTypeId === 2);
        
        state.userPrivacityConfigurations.contents = contents;
        state.userPrivacityConfigurations.projects = projects;
      })
      .addCase(getUserGenericConfig.rejected, (state) => {
        state.loading = false
      })
    }
    })

export const userConfigSliceReducers = userConfigSlice.reducer
export const userConfigSliceActions = userConfigSlice.actions

export function sortAndMergeGroups(...lists: any[][]): any[] {

    const mergedList = ([] as any[]).concat(...lists);

    const compareDates = (a: string, b: string) => {
    const dateA = new Date(a);
    const dateB = new Date(b);
    return  dateB.getTime() - dateA.getTime();
    };

    mergedList.sort((a, b) => compareDates(a.created_at, b.created_at));

    const mergedListConverted = convertKeysToCamelCase(mergedList);
    return mergedListConverted;
}

export function convertKeysToCamelCaseAndSortByUpdatedAt(obj: any): any {
  function convertKeysToCamelCase(inputObj: any): any {
    if (typeof inputObj === 'object' && inputObj !== null) {
      if (Array.isArray(inputObj)) {
        return inputObj.map(item => convertKeysToCamelCase(item));
      } else {
        if (inputObj.constructor === Object) {
          const newObj: { [key: string]: any } = {};
          for (const key in inputObj) {
            if (Object.prototype.hasOwnProperty.call(inputObj, key)) {
              const camelCaseKey = key.replace(/_([a-z])/g, (match, letter) =>
                letter.toUpperCase()
              );
              newObj[camelCaseKey] = convertKeysToCamelCase(inputObj[key]);
            }
          }
          return newObj;
        }
      }
    }
    return inputObj;
  }

const camelCasedObj = convertKeysToCamelCase(obj);

  if (Array.isArray(camelCasedObj)) {
    return camelCasedObj.sort((a, b) => {
      const dateA = new Date(a.updatedAt);
      const dateB = new Date(b.updatedAt);
      return dateB.getTime() - dateA.getTime();
    });
  } else {
    return camelCasedObj;
  }
}

export function convertKeysToCamelCase(obj: any): any {
  if (typeof obj === 'object' && obj !== null) {
    if (Array.isArray(obj)) {
      return obj.map(item => convertKeysToCamelCase(item));
    } else if (obj.constructor === Object) {
      const newObj: { [key: string]: any } = {};
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const camelCaseKey = key.replace(/_([a-z])/g, (_, letter) =>
            letter.toUpperCase()
          );
          newObj[camelCaseKey] = convertKeysToCamelCase(obj[key]);
        }
      }
      return newObj;
    }
  }
  return obj;
}

function convertKeysToSnakeCase(obj: any): any {
  if (typeof obj === 'object' && obj !== null) {
    if (Array.isArray(obj)) {
      return obj.map(item => convertKeysToSnakeCase(item));
    } else {
      if (obj.constructor === Object) {
        const newObj: { [key: string]: any } = {};
        for (const key in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const snakeCaseKey = key === 'accessToken' ? key : key.replace(/([A-Z])/g, (match) => `_${match.toLowerCase()}`);
            newObj[snakeCaseKey] = obj[key];
          }
        }
        return newObj;
      }
    }
  }
  return obj;
}