import { useEffect, useRef, useState } from 'react';
import NotificationsIcon from '../../assets/bell-icon-24-white.png';
import './notifications.css';
import { useDispatch, useSelector } from 'react-redux';
import { getUserNotifications } from '../sessions/sessionNotification';
import { AppDispatch, RootState } from '../../store';
import { Notifiable, Notification, ProjectNotification, User, UserNotification } from "../../#interfaces/interfaces";
import { getProjectContents, manageProjectContentsSliceActions } from '../sessions/projectsManagement/sessionManageProject';
import * as REQUEST_REQUIREMENTS from '../../api/requestRequirements';
import { useNavigate } from 'react-router-dom';
import DefaultProfileImage from '../../assets/profile-default-red.png'
import { CircularProgress } from '@mui/material';

export default function Notifications() {
    const currentUser = useSelector((state: RootState) => state.session.currentUser);
    const authHeaders = useSelector((state: RootState) => state.session.authHeaders);
    const notifications = useSelector((state: RootState) => state.sessionNotifications.notifications);
    const [openToggleNotifications, setOpenToggleNotifications] = useState(false);
    const loadingMoreNotifications = useSelector((state: RootState) => state.sessionNotifications.loadingMoreNotifications);
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const notificationsRef = useRef<HTMLDivElement>(null);
    const [notificationsPagination, setNotificationsPagination] = useState<number>(1);
    const containerToggleNotifications = useRef<HTMLDivElement>(null);

    useEffect(() => {
        async function fetchNotificationsData() {
            if (authHeaders && currentUser) {
                dispatch(getUserNotifications({ authHeaders: authHeaders , 
                                                urlParams: { 
                                                    page: notificationsPagination 
                                                }}));
            }
        }
        fetchNotificationsData();
    }, [currentUser,notificationsPagination]);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                notificationsRef.current &&
                !notificationsRef.current.contains(event.target as Node)
            ) {
                setOpenToggleNotifications(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const container = containerToggleNotifications.current;
            if (!container) {
                return;
            }
    
            const { scrollTop, clientHeight, scrollHeight } = container;
            
            if (scrollTop + clientHeight >= scrollHeight - 20 && !loadingMoreNotifications) {
                setNotificationsPagination((prevPage) => prevPage + 1);
            }
        };
    
        const container = containerToggleNotifications.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
            return () => {
                container.removeEventListener('scroll', handleScroll);
            };
        }
    }, [loadingMoreNotifications,openToggleNotifications]);
    

    async function handleGetProjectContents(project: ProjectNotification) {
        setOpenToggleNotifications(false);

        const getParams = {
            authHeaders,
            userNickname: project.user.userMoreInfo.nickname,
            projectId: project.id
        };

        manageProjectContentsSliceActions.loadingPage();

        const response = await dispatch(getProjectContents(getParams));

        if (response) {
            const routeParams = {
                userNickname: project.user.userMoreInfo.nickname,
                projectId: project.id
            };
            const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ ROUTE_PARAMS: routeParams });

            if (PRIVATE_ROUTES) {
                navigate(PRIVATE_ROUTES.PROJECT_CONTENTS);
            }
        }
    }

    async function handleNavigationUserProfile( userTarget: UserNotification ){


        const routeParams={
            userNickname: userTarget.userMoreInfo.nickname
        }

        const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: routeParams});

        return navigate(PRIVATE_ROUTES.USER)
    }

    return (
        <div id="container-notifications" ref={notificationsRef}>
            <button
                id="button-open-close-toggle-notifications"
                onClick={() => setOpenToggleNotifications(!openToggleNotifications)}
            >
                {notifications.length > 10 ? (
                    <div id="container-notifications-tag">
                        <h6 id="notifications-number">+10</h6>
                    </div>
                ) : (
                    <div id="container-notifications-tag">
                        <h6 id="notifications-number">{notifications.length}</h6>
                    </div>
                )}
                <img src={NotificationsIcon} alt="Notifications" />
            </button>

            {openToggleNotifications && (
                <div id="container-toggle-notifications" ref={containerToggleNotifications}>
                    <ul>
                        {notifications.length > 0 &&
                            notifications.map((item: Notification, index: number) => {

                                return (
                                    <li
                                        id="li-notifications"
                                        key={index}
                                        onClick={() => {
                                            if (item.notifiableType == 'User' && item.actorUser.userMoreInfo) {
                                                setOpenToggleNotifications(!openToggleNotifications)
                                                return handleNavigationUserProfile(item.actorUser as UserNotification);
                                            } else if (item.notifiableType == 'Project' && item.actorUser.userMoreInfo) {
                                                setOpenToggleNotifications(!openToggleNotifications)
                                                return handleGetProjectContents(item.notifiable as ProjectNotification);
                                            }
                                        }}
                                    >
                                        {item.actorUser.userMoreInfo.avatarUrl ?
                                            <img
                                                src={item.actorUser.userMoreInfo.avatarUrl}
                                                alt="Avatar"
                                            />
                                        :
                                            <img
                                                src={DefaultProfileImage}
                                                alt="Avatar"
                                            />
                                        }
                                        <h5
                                            id="h5-notification"
                                            style={{ userSelect: "none" }}
                                            dangerouslySetInnerHTML={{ __html: item.message }}
                                        ></h5>
                                    </li>
                                    
                                );
                                
                            })}
                            {
                                loadingMoreNotifications ?
                                    <div id='container-loading-spinner'>
                                        <CircularProgress color='secondary' sx={{ color: 'brown' }} />
                                    </div>
                                    :
                                    null
                                }
                    </ul>
                </div>
            )}
        </div>
    );
}
