import React, { useEffect } from 'react';
import './addRemoveUserMusicalGenres.css';
import { FiX } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store';
import { patchUserMusicalGenre, getMusicalGenresAvailable, createUserMusicalGenre } from '../../../../features/sessions/sessionProfileNavigation';
import { MusicGenre, UserMusicalGenre } from '../../../../#interfaces/interfaces';

export default function AddRemoveUserMusicalGenres({userNickname,onClose}:any){
    const authHeaders = useSelector((state: RootState) => state.session.authHeaders);
    const currentUser = useSelector((state: RootState) => state.session.currentUser);
    const dispatch = useDispatch<AppDispatch>();
    const userMusicalGenres = useSelector((state: RootState) => state.sessionProfile.userMusicalGenres);
    const musicalGenresAvailable = useSelector((state: RootState) => state.sessionProfile.musicalGenresAvailable);

    
    useEffect(() => {

        async function fetchMusicalGenresAvailable() {
            if (authHeaders){
                const response = await dispatch(getMusicalGenresAvailable({authHeaders: authHeaders}))
            }
        }
     
        fetchMusicalGenresAvailable();

    },[])

    async function handleUpdateCreateUserMusicalGenre(musicGenreId: number, selected: boolean) {

        if (authHeaders && userNickname && currentUser && currentUser.nickname && currentUser.id) {

            const userMusicalGenre = userMusicalGenres.find((userMusicalGenre: UserMusicalGenre) => 
                userMusicalGenre.musicGenre.id === musicGenreId
            );
             
            if (userMusicalGenre?.selected === false) {
                const response = await dispatch(patchUserMusicalGenre({
                    headers: authHeaders,
                    urlParams: {
                        userNickname: currentUser.nickname
                    },
                    requestBodyData: {
                        musicGenreId: musicGenreId,
                        selected: selected
                    }
                }));
            } else if (userMusicalGenre?.id === undefined || !userMusicalGenre?.selected){
                const response = await dispatch(createUserMusicalGenre({
                    headers: authHeaders,
                    urlParams: {
                        currentUserNickname: userNickname
                    },
                    requestBodyData: {
                                        userId: currentUser.id,
                                        musicGenreId: musicGenreId
                    }
                }));
            }
        }
    }    

    async function handleUpdateUserMusicalGenre(musicGenreId: number, selected: boolean){
        if (authHeaders && userNickname && currentUser && currentUser.nickname && currentUser.id) {
    
            const userMusicalGenre = userMusicalGenres.find((userMusicalGenre: UserMusicalGenre) => 
                userMusicalGenre.musicGenre.id === musicGenreId
            );
    
            if (userMusicalGenre?.selected === true) {

                const response = await dispatch(patchUserMusicalGenre({
                    headers: authHeaders,
                    urlParams: {
                        userNickname: userNickname
                    },
                    requestBodyData: {
                        musicGenreId: musicGenreId,
                        selected: selected
                    }
                }));
            } 
        }
    }


    return(
            <>
                <div id='container-add-remove-user-musical-genres-background-modal'>
                    <div id='container-screen-transparent-add-remove-user-musical-genres-modal'>
                        <div id='container-add-remove-user-musical-genres-modal'>
                            <div id='container-top-add-remove-user-musical-genres-modal'>
                                <h2>Genres Preferences</h2>
                                <button onClick={ () => onClose(false) }>
                                    <FiX size={25} color="#FFF"/>
                                </button>
                            </div>
                            <div id='container-list-add-remove-user-musical-genres-modal'>
                                <ul>
                                    {musicalGenresAvailable && musicalGenresAvailable.map((musicGenre: MusicGenre, index: number) => {
                                        const isSelected = userMusicalGenres.some(userMusicalGenre => userMusicalGenre.musicGenre.description === musicGenre.description 
                                            && userMusicalGenre.selected === true);
                                        return (
                                        <React.Fragment key={index}>
                                            {isSelected ? (
                                            <li id='li-user-music-genres-already-selected' 
                                                key={`${index}-selected`} 
                                                onClick={() => handleUpdateUserMusicalGenre(musicGenre.id, false)}>
                                                <h3>

                                                    {musicGenre.description}
                                                </h3>
                                            </li>
                                            ) : (
                                            <li id='li-music-genres-available' 
                                                key={`${index}-available`} 
                                                onClick={() => handleUpdateCreateUserMusicalGenre(musicGenre.id, true)}>
                                                <h3>
                                                    +{musicGenre.description}
                                                </h3>
                                            </li>
                                            )}
                                        </React.Fragment>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    )
}