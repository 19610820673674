import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { notificationsSliceActions } from "../sessions/sessionNotification";
import { AppDispatch, RootState } from "../../store";

const useSocketSetup = (socket: any) => {
  const currentUser = useSelector((state: RootState) => state.session.currentUser);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    socket.connect();
  
    socket.on("connect", () => {
      console.log("listening connect");
    });
  
    if (currentUser) {
      const notificationEvent = `notification:recipient:2`;
      
      socket.on(notificationEvent, (message: any) => {
        dispatch(notificationsSliceActions.incrementNotifications(JSON.parse(message)));
      });
  
      return () => {
        socket.off(notificationEvent);
      };
    }
  
    return () => {
      socket.off("connect");
      socket.disconnect();
    };
  }, [socket, dispatch, currentUser]);
}

export default useSocketSetup;
