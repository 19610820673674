import { FiX } from "react-icons/fi";
import LoadingModal from "../loadingModal/LoadingModal";
import { Dispatch, SetStateAction, useState } from "react";
import './modalSelectGroupGenres.css';
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { MusicGenre } from "../../../#interfaces/interfaces";

interface ModalSelectGroupGenresProps {
    setSelectedGenres: React.Dispatch<React.SetStateAction<number[]>>;
    selectedGenres: number[];
    close: Dispatch<SetStateAction<boolean>>;
}

export function ModalSelectGroupGenres({ selectedGenres, setSelectedGenres, close }: ModalSelectGroupGenresProps){
    const loading = false;
    const [modalOpen,setModalOpen] = useState(true);
    const allMusicalGenres = useSelector((state: RootState) => state.sessionGroups.musicalGenres);
    // const [selectedGenres, setSelectedGenres] = useState<number[]>([]);

    const handleGenreSelection = (genreId: number) => {
        // Verifica se o gênero já está selecionado
        if (selectedGenres.includes(genreId)) {
            // Se já estiver selecionado, remove da lista de selecionados
            setSelectedGenres(selectedGenres.filter(id => id !== genreId));
        } else {
            // Se não estiver selecionado, adiciona à lista de selecionados
            setSelectedGenres([...selectedGenres, genreId]);
        }
    };

    return(
        <>
        {
            loading ?
                <LoadingModal/> 
                
            :
        
            <div id='modal-group-select-genre'>
                <div id='modal-group-genres-container'>
                    <button id='close-group-genre-selection' onClick={ () => close(false) }>
                            <FiX size={25} color="#FFF"/>
                    </button>
                    <div id='container-title-modal-create-group'>
                        <h2>Select Group Genres</h2>
                    </div>
                    <div id='container-music-genres-list'>
                        <ul id='ul-musical-genre'>
                        {allMusicalGenres.map((item: MusicGenre, index: number) => (
                            <li id='li-musical-genre' key={index}>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={selectedGenres.includes(item.id)}
                                        onChange={() => handleGenreSelection(item.id)}
                                    />
                                    <div className="custom-checkbox"></div>
                                    <div id="container-musical-genre-description">
                                        <h5 id="h5-musical-genre-description">
                                            {item.description}
                                        </h5>
                                    </div>
                                </label>
                            </li>
                        ))}
                        </ul>
                    </div>
                </div>
            </div>
        }
        </>
    )
}