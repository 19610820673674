import React, { useEffect } from 'react';
import './projectContentInfo.css';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store';
import { getProjectContent } from '../../../../features/sessions/sessionProjectManagementPlayer/sliceProjectManagementPlayer';
import { FiX } from 'react-icons/fi';
import { FaLock, FaUnlock } from 'react-icons/fa';

interface ProjectContentInfoProps {
    projectContentId: string;
    userNicknameUrlParams: string;
    onCloseClick: (confirmation: boolean) => void;
}

export function ProjectContentInfo({ projectContentId, userNicknameUrlParams, onCloseClick }: ProjectContentInfoProps){
    const authHeaders = useSelector((state: RootState) => state.session.authHeaders);
    const dispatch = useDispatch<AppDispatch>();
    const currentProjectContent = useSelector((state: RootState) => state.sessionProjectPlayerManagement.currentProjectContentToShow);

    useEffect(()=>{

        async function getContent() {

             const response = await dispatch(getProjectContent({
                                                                authHeaders: authHeaders,
                                                                routeParams: {
                                                                    projectContentId: `${projectContentId}`,
                                                                    userNickname: userNicknameUrlParams,
                                                                }}))
        }

        getContent();
    },[])

    function handleFormatDate(dateString: string | undefined): string | null {
        if (!dateString) return null;
    
        const date = new Date(dateString);
    
        const options: Intl.DateTimeFormatOptions = {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
        };
    
        const locale = navigator.language;
    
        const formattedDate = date.toLocaleString(locale, options);
    
        return formattedDate;
    }

    function centisecondsToTime(centiseconds: number | string): string {

        const centisecondsInt = typeof centiseconds === 'string' ? parseInt(centiseconds) : centiseconds;
    
        if (!Number.isInteger(centisecondsInt)) {
            throw new Error('O valor deve ser um número inteiro.');
        }
    
        const totalSeconds = Math.floor(centisecondsInt / 100);
    
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        const remainingCentiseconds = centisecondsInt % 100;
    
        const formattedTime = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}:${String(remainingCentiseconds).padStart(2, '0')}`;
    
        return formattedTime;
    }
    
    return(<>

            <div id="container-project-content-info-modal">

                <div id='container-screen-transparent-project-content-info'>
                        <div id='container-button-close-project-content-info-modal'>
                            <button onClick={() => onCloseClick(false)}>
                                <FiX size={25} color="#FFF"/>
                            </button>
                        </div>
                        <div id="container-project-content-info">
                                <div id='container-top-project-content-info-modal'>
                                    <div id='container-title-description-project-content-info'>
                                        <div id='container-content-info-project-content-info-modal'>
                                            <div id='container-title-description-project-content-info-modal'>
                                                <div id='container-title-download-abled-project-content-info-modal'>
                                                    <h1>{currentProjectContent?.content?.title}</h1>
                                                    <div id='container-download-abled-project-content-info-modal'>
                                                        <label>Download abled:</label>
                                                        {
                                                            currentProjectContent?.content?.downloadAbled ?
                                                                <FaUnlock/>   
                                                            :
                                                                <FaLock />
                                                        }
                                                    </div>
                                                </div>
                                                {currentProjectContent?.content?.description ?
                                                
                                                <p>{currentProjectContent?.content?.description}</p>
                                                
                                                :

                                                null
                                            }
                                            </div>
                                        </div>
                                    {
                                        currentProjectContent?.content?.file?.blob.metadata.centiseconds 
                                        
                                        ?

                                        <div id='container-content-duration-project-content-info-modal'>
                                            <label>
                                                Duration:
                                            </label>
                                            <h6>{centisecondsToTime(currentProjectContent?.content?.file?.blob.metadata.centiseconds)}</h6>
                                        </div>

                                        :

                                        null

                                    }
                                    </div>
                                </div>
                                <div id='container-middle-project-content-info-modal'>
                                    <div id='container-left-top-project-content-info-modal'>
                                        <div id='container-user-info-project-containet-info'>
                                            <img src={currentProjectContent?.content?.user?.userMoreInfo?.avatarUrl}/>
                                            <div id='container-user-name-nickname-project-content'>
                                                <a id='user-nickname-project-content-details'>
                                                    {currentProjectContent?.content?.user?.nickname}
                                                </a>
                                                {/* {currentProjectContent?.content?.user?.userMoreInfo?.name ?
                                                    <>
                                                        <h6>{currentProjectContent?.content?.user?.userMoreInfo?.name}
                                                            {' '}
                                                            {currentProjectContent?.content?.user?.userMoreInfo?.lastName}
                                                        </h6>
                                                    </>
                                                    :
                                                    <h6>undefined</h6>
                                                } */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id='container-bottom-project-content-info-modal'>
                                    <div id='container-instrument-project-content-modal-info'>
                                        <label>
                                            Instrument:
                                        </label>
                                        {
                                            currentProjectContent?.content?.instrument?.name ?

                                            <a>{currentProjectContent?.content?.instrument?.name}</a>

                                            :

                                            <a>outro</a>
                                        }
                                        
                                    </div>
                                    <div id='container-botto-right-project-content-info-modal'>
                                        <label>Attached at:</label>
                                        <a>{handleFormatDate(`${currentProjectContent?.createdAt}`)}</a>
                                    </div>

                                </div>
                        </div>
                </div>
            </div>
    </>
    )
}