import axios, { AxiosRequestConfig } from "axios";
import * as REQUEST_REQUIREMENTS from './requestRequirements';
import { AuthHeaders, GetGroupPostsRouteParams, GetGroupRouteParams } from "../#interfaces/interfaces";
import { DeleteProjectUrlParams, GetProjectsRouteParams} from '../features/sessions/projectsManagement/sessionManageProjects'
import { PatchConfigBody, PatchPrivacityConfigBody } from "../features/sessions/sessionConfig/sessionUserConfigurations";
import { CreateCommentInPostRouteParams, CreatePostBody, CreatePostRouteParams, DeletePostCommentRouteParams, DeletePostRouteParams, PostCreationBody, ShowPostWithCommentsRouteParams } from "../features/sessions/sessionPostProject/sessionPostProject";
import { CreateProjectContentBody, CreateProjectContentCommentRouteParams, DeleteProjectContentCommentUrlParams, GetProjectContentCommentsUrlParams, GetProjectContentUrlParams } from "../features/sessions/sessionProjectManagementPlayer/sliceProjectManagementPlayer";
import { PatchUserInstrumentUrlParams, PatchUserMusicalGenreBodyData, PatchUserMusicalGenreUrlParams, 
         GetUserInstrumentUrlParams, GetUserMusicalGenresUrlParams, PatchUserInstrumentBodyData, 
         CreateUserFellowshipRequestBodyData,
         GetCurrentUserFelowsUrlParams,
         PatchUserFellowshipRequestUrlParams,
         PatchBodyUserFellowship,
         CreateUserInstrumentUrlParams,
         CreateUserInstrumentBodyData,
         CreateUserMusicalGenreBodyData,
         CreateUserMusicalGenreUrlParams} from "../features/sessions/sessionProfileNavigation";
import { GetSearchResultsRouteParams } from "../features/sessions/sessionSearch/sliceSearch";
import { DeleteContentUrlParams, GetContentsRouteParams, PatchContentBody, PatchContentUrlParams } from "../features/sessions/sessionAudioContent/sliceUserContents";
import { GetUserNotificationsUrlParams } from "../features/sessions/sessionNotification";
import { GetUserDialoguesUrlParams, PostUserDialogueBody, PostUserDialogueUrlParams } from "../features/sessions/sessionDialogues";

export const api = axios.create({
    baseURL: process.env.REACT_APP_API,
    withCredentials: true
})

export async function createUserWithEmailAndPassword(
    nickname: string,
    email: string,
    password: string,
    password_confirmation: string
    ) {
    const data = {
        nickname: nickname,
        email: email,
        password: password,
        password_confirmation: password_confirmation,
        confirm_success_url: `${process.env.DEFAULT_URL}`
    };

    return api
        .post(REQUEST_REQUIREMENTS.SIGNUP_ENDPOINT, data)
        .then((response: any) => {
            return response.data;
        })
        .catch((error:any) => {
            return error.response;
        });
}

export async function signInUserWithEmailAndPassword(email: string,password: string) {
    const data = {
        email: email,
        password: password
    }
    return api
        .post(REQUEST_REQUIREMENTS.SIGNIN_ENDPOINT, data)
        .then((response: any) => {
            return response;
        })
        .catch((error: any) => {
            return error.response;
        });
}

export async function validateAuthHeader(headers: AuthHeaders){

    const authHeaders = convertKeysToSnakeCase(headers)
    // "Access-Control-Allow-Origin": "*",

    return api.get(REQUEST_REQUIREMENTS.VALIDATE_TOKEN_ENDPOINT, { headers: authHeaders,
        withCredentials: true })
    .then((response: any) => {
        return response;
    })
    .catch((error: any) => {
        return error.response;
    });
}

export async function signOutUserWithAuthHeaders(headers: any) {
    return api
        .delete(REQUEST_REQUIREMENTS.SIGNOUT_ENDPOINT, {
            headers: {
                "Accept": headers.accept,
                "access-token": headers.accessToken,
                "client": headers.client,
                "uid": headers.uid
            }
        })
        .then((response: any) => {
            return response;
        })
        .catch((error: any) => {
            return error.response.data;
        });
}

export async function getUser(headers: any, userNicknameRoute: any){
    
    const routeParams={
        userNickname: userNicknameRoute
    }

    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: routeParams});

    if(PRIVATE_ROUTES){
        return api
            .get(PRIVATE_ROUTES.USER, {
                headers: {
                    "Accept": headers.accept,
                    "access-token": headers.accessToken,
                    "client": headers.client,
                    "uid": headers.uid
                },
                withCredentials: true
            })
            .then((response: any) => {
                return response
            })
            .catch((error: any) => {
                return error.response
            });
    }
}

export async function getInstrumentsAvailable(){
    
        return api
            .get(REQUEST_REQUIREMENTS.INSTRUMENTS, {
                headers: {
                }
            })
            .then((response: any) => {
                return response
            })
            .catch((error: any) => {
                return error.response
            });
            
}

export async function getContents(headers: AuthHeaders, routeParams: GetContentsRouteParams){
    
    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: routeParams});

    if(PRIVATE_ROUTES){
        return api
            .get(PRIVATE_ROUTES.USER_CONTENTS, {
                headers: {
                    "Accept": headers.accept,
                    "access-token": headers.accessToken,
                    "client": headers.client,
                    "uid": headers.uid,
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then((response: any) => {
                return response
            })
            .catch((error: any) => {
                return error.response
            });
    }
}

export async function postContent(headers: any, currentUser: any, body: any){

    const routeParams={
        userNickname: currentUser.nickname
    }

    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: routeParams});

    const formData = new FormData();

    formData.append("title",body["title"]);
    formData.append("description",body["description"]);
    formData.append("type_id",body["type_id"]);
    formData.append("download_abled",body["download_abled"]);
    formData.append("instrument_id",body["instrument_id"]);
    formData.append("file",body["file"]);

    const headerRequest: AxiosRequestConfig = {
                                                headers: {
                                                                "access-token": headers.accessToken,
                                                                "client": headers.client,
                                                                "uid": headers.uid
                                                                },
                                                                withCredentials: true 
    };

        if(PRIVATE_ROUTES){
            return api.post(PRIVATE_ROUTES.CREATE_CONTENT, formData, headerRequest)
                .then((response: any) => {
                    return response
                })
                .catch((error: any) => {
                    return error.response;
                });
        }
}

export async function patchContentEdit(headers: AuthHeaders, urlParams: PatchContentUrlParams, body: PatchContentBody){

    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: urlParams});

    const patchBodySnakeCase = convertKeysToSnakeCase(body)
    const headersSnakeCase = convertKeysToSnakeCase(headers)
    
    if(PRIVATE_ROUTES){
        return api.patch(PRIVATE_ROUTES.UPDATE_USER_CONTENT, patchBodySnakeCase, {headers: headersSnakeCase})
            .then((response: any) => {
                return response
            })
            .catch((error: any) => {
                return error.response;
            });
    }
}

export async function deleteUserContentRequest(headers: AuthHeaders, urlParams: DeleteContentUrlParams){

    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: urlParams});

    const headersSnakeCase = convertKeysToSnakeCase(headers)
    
    if(PRIVATE_ROUTES){
        return api.delete(PRIVATE_ROUTES.UPDATE_USER_CONTENT, {headers: headersSnakeCase})
            .then((response: any) => {
                return response
            })
            .catch((error: any) => {
                return error.response;
            });
    }
}

export async function getProjects(headers: AuthHeaders, routeParams: GetProjectsRouteParams){
    

    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: routeParams});

    const headerRequest: AxiosRequestConfig = {
                                                headers: {
                                                                "access-token": headers.accessToken,
                                                                "client": headers.client,
                                                                "uid": headers.uid
                                                                }
    };

        if(PRIVATE_ROUTES){
            return api.get(PRIVATE_ROUTES.GET_USER_PROJECTS, headerRequest)
                .then((response: any) => {
                    return response
                })
                .catch((error: any) => {
                    return error.response;
                });
        }
}

export async function postProject(headers: any, currentUser: any, postBody: any){
    
    const routeParams={
        userNickname: currentUser.nickname
    }

    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: routeParams});

    const headerRequest: AxiosRequestConfig = {
                                                headers: {
                                                                "access-token": headers.accessToken,
                                                                "client": headers.client,
                                                                "uid": headers.uid,
                                                                "accept": headers.accept
                                                                }
    };

    if(PRIVATE_ROUTES){
        return api.post(PRIVATE_ROUTES.CREATE_PROJECT,postBody, headerRequest)
            .then((response: any) => {
                return response
            })
            .catch((error: any) => {
                return error.response;
            });
    }
}

export async function deleteProjectWithId(headers: AuthHeaders, urlParams: DeleteProjectUrlParams){

    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: urlParams});

    const headersSnakeCase = convertKeysToSnakeCase(headers)

    if(PRIVATE_ROUTES){
        return api.delete(PRIVATE_ROUTES.DELETE_USER_PROJECT, {headers: headersSnakeCase})
            .then((response: any) => {
                return response
            })
            .catch((error: any) => {
                return error.response;
            });
    }
}

export async function getContentsInProject(headers: any, userNickname: any, projectId: any){

    const routeParams={
        userNickname: userNickname,
        projectId: projectId
    }

    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: routeParams});

    const headersSnakeCase = convertKeysToSnakeCase(headers)

    if(PRIVATE_ROUTES && PRIVATE_ROUTES.PROJECT_CONTENTS){
        return api.get(PRIVATE_ROUTES.PROJECT_CONTENTS, {headers: headersSnakeCase})
            .then((response: any) => {
                return response
            })
            .catch((error: any) => {
                return error.response;
            });
    }
}

export async function getProjectContentWithId(headers: any, routeParams: GetProjectContentUrlParams){

    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: routeParams});

    const headersSnakeCase = convertKeysToSnakeCase(headers)

    if(PRIVATE_ROUTES && PRIVATE_ROUTES.PROJECT_CONTENTS){
        return api.get(PRIVATE_ROUTES.PROJECT_CONTENT, {headers: headersSnakeCase})
            .then((response: any) => {
                return response
            })
            .catch((error: any) => {
                return error.response;
            });
    }
}

export async function getContentsInProjectToMix(headers: any, userNickname: any, projectId: any, lisstProjectContentsId: any){

    const routeParams = {
        userNickname: userNickname,
        projectId: projectId
    }

    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: routeParams});

    const headersSnakeCase = convertKeysToSnakeCase(headers)

    if(PRIVATE_ROUTES && PRIVATE_ROUTES.PROJECT_CONTENTS){
        return api.get(PRIVATE_ROUTES.PROJECT_CONTENTS, {headers: headersSnakeCase})
            .then((response: any) => {
                return response
            })
            .catch((error: any) => {
                return error.response;
            });
    }
}

export async function postContentInProject(headers: any, projectContentsRouteParams: any, postBody: any){

    const routeParams={
        userNickname: projectContentsRouteParams.userNicknameRoute,
        projectId: projectContentsRouteParams.projectIdRoute
    }

    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: routeParams});

    const postBodySnakeCase = convertKeysToSnakeCase(postBody)
    const headersSnakeCase = convertKeysToSnakeCase(headers)

    if(PRIVATE_ROUTES){

        return api.post(PRIVATE_ROUTES.PROJECT_CONTENTS, postBodySnakeCase, {headers: headersSnakeCase})
            .then((response: any) => {
                return response
            })
            .catch((error: any) => {
                return error.response;
            });
    }
}

export async function editContentInProject(headers: any, projectContentsRouteParams: any, patchBody: any){

    const routeParams={
        userNickname: projectContentsRouteParams.userNicknameRoute,
        projectId: projectContentsRouteParams.projectIdRoute
    }

    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: routeParams});

    const patchBodySnakeCase = convertKeysToSnakeCase(patchBody)
    const headersSnakeCase = convertKeysToSnakeCase(headers)

    if(PRIVATE_ROUTES){

        return api.patch(PRIVATE_ROUTES.PROJECT_CONTENTS,
                            patchBodySnakeCase, 
                            { headers: headersSnakeCase })
            .then((response: any) => {
                return response
            })
            .catch((error: any) => {
                return error.response;
            });
    }
}

export async function patchProject(headers: any, projectContentsRouteParams: any, patchBody: any){

    const routeParams={
        userNickname: projectContentsRouteParams.userNicknameRoute,
        projectId: projectContentsRouteParams.projectIdRoute
    }

    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: routeParams});

    const patchBodySnakeCase = convertKeysToSnakeCase(patchBody)
    const headersSnakeCase = convertKeysToSnakeCase(headers)

    if(PRIVATE_ROUTES){

        return api.patch(PRIVATE_ROUTES.PROJECT,
                            patchBodySnakeCase, 
                            { headers: headersSnakeCase })
            .then((response: any) => {
                return response
            })
            .catch((error: any) => {
                return error.response;
            });
    }
}

export async function deleteProjectContent(headers: any, projectContentsRouteParams: any){

    const routeParams={
        userNickname: projectContentsRouteParams.userNicknameRoute,
        projectContentId: projectContentsRouteParams.projectContentId
    }

    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: routeParams});

    const headersSnakeCase = convertKeysToSnakeCase(headers)

    if(PRIVATE_ROUTES){

        return api.delete(PRIVATE_ROUTES.PROJECT_CONTENT,
                            { headers: headersSnakeCase })
            .then((response: any) => {
                return response
            })
            .catch((error: any) => {
                return error.response;
            });
    }
}

export async function getProjectContentinProject(headers: any, routeParams: GetProjectContentUrlParams){

    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: routeParams});

    const headersSnakeCase = convertKeysToSnakeCase(headers)

    if(PRIVATE_ROUTES){

        return api.get(PRIVATE_ROUTES.PROJECT_CONTENT,
                            { headers: headersSnakeCase })
            .then((response: any) => {
                return response
            })
            .catch((error: any) => {
                return error.response;
            });
    }
}

export async function postFileOnProject(headers: any, projectContentsRouteParams: any, postBody: any){

    const formData = new FormData();

    const fileContentResponse = await axios.get(postBody.url, { responseType: 'blob' });
    const fileBlob = fileContentResponse.data;

    formData.append('project_file[file]', fileBlob);
    
    const headerRequest: AxiosRequestConfig = {headers: {
                                                                "access-token": headers.accessToken,
                                                                "client": headers.client,
                                                                "uid": headers.uid
                                                                }
};

    const routeParams={
        userNickname: projectContentsRouteParams.userNicknameRoute,
        projectId: projectContentsRouteParams.projectIdRoute
    }

    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: routeParams});

    if(PRIVATE_ROUTES){
        
        return api.post(PRIVATE_ROUTES.POST_FILE_PROJECT,
                            formData, 
                            headerRequest )
            .then((response: any) => {
                return response
            })
            .catch((error: any) => {
                return error.response;
            });
    }
}

export async function getSearchBar(headers: any, propertyToSearch: any, fieldInput: any){

    const routeParams = {
        propertyToSearch: propertyToSearch,
        searchTerm: fieldInput
    }

    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: routeParams});

    const headersSnakeCase = convertKeysToSnakeCase(headers)

    if(PRIVATE_ROUTES && PRIVATE_ROUTES.PROJECT_CONTENTS){
        return api.get(PRIVATE_ROUTES.SEARCH, {headers: headersSnakeCase})
            .then((response: any) => {
                return response
            })
            .catch((error: any) => {
                return error.response;
            });
    }
}

export async function mixAudios(projectId: any ,selectedContents: any){

    const requestBody = {
        projectId: projectId,
        listProjectContents: selectedContents
    }

    return api.post(REQUEST_REQUIREMENTS.MIX_AUDIOS, convertKeysToSnakeCase(requestBody))
        .then((response: any) => {
            return response
        })
        .catch((error: any) => {
            return error.response;
        });
    
}

export async function getMergedAudioFromProject(projectId: any){

    const requestBody = {
        projectId: projectId
    }

    const routeParams = {
        projectId: projectId
    }

    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: routeParams});

    return api.get(PRIVATE_ROUTES.MERGED_AUDIO_PROJECT, convertKeysToSnakeCase(requestBody))
        .then((response: any) => {
            return response
        })
        .catch((error: any) => {
            return error.response;
        });
    
}

export async function patchAvatar(headers: AuthHeaders, userNickname: string, patchBody: File){

    const routeParams={
        userNickname: userNickname
    }

    const formData = new FormData();
    formData.append('avatar', patchBody);

    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: routeParams});

    const headersSnakeCase = convertKeysToSnakeCase(headers)

    if(PRIVATE_ROUTES){

        return api.patch(PRIVATE_ROUTES.UPDATE_GENERAL_CONFIG,
                            formData, 
                            { headers: headersSnakeCase })
            .then((response: any) => {
                return response
            })
            .catch((error: any) => {
                return error.response;
            });
    }
}

export async function patchGerneralConfig(headers: AuthHeaders, userNickname: string, patchBody: PatchConfigBody){

    const routeParams={
        userNickname: userNickname
    }

    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: routeParams});

    const headersSnakeCase = convertKeysToSnakeCase(headers)
    const patchBodySnakeCase = convertKeysToSnakeCase(patchBody)

    if(PRIVATE_ROUTES){

        return api.patch(PRIVATE_ROUTES.UPDATE_GENERAL_CONFIG,
                         patchBodySnakeCase, 
                         { headers: headersSnakeCase })
            .then((response: any) => {
                return response
            })
            .catch((error: any) => {
                return error.response;
            });
    }
}

export async function getUserGenericConfigurations(headers: AuthHeaders, userNickname: string){

    const routeParams={
        userNickname: userNickname
    }

    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: routeParams});

    const headersSnakeCase = convertKeysToSnakeCase(headers)

    if(PRIVATE_ROUTES){

        return api.get(PRIVATE_ROUTES.GET_GENERAL_CONFIG,
                         { headers: headersSnakeCase })
            .then((response: any) => {
                return response
            })
            .catch((error: any) => {
                return error.response;
            });
    }
}

export async function getMusicGenres(headers: AuthHeaders){

    const headersSnakeCase = convertKeysToSnakeCase(headers)

    return api.get(REQUEST_REQUIREMENTS.MUSICAL_GENRES,
                        { headers: headersSnakeCase })
        .then((response: any) => {
            return response
        })
        .catch((error: any) => {
            return error.response;
        });
}

export async function patchPrivacityConfig(headers: AuthHeaders, userNickname: string, patchBody: PatchPrivacityConfigBody | undefined){

    const routeParams={
        userNickname: userNickname
    }

    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: routeParams});

    const headersSnakeCase = convertKeysToSnakeCase(headers)
    const patchBodySnakeCase = convertKeysToSnakeCase(patchBody)

    if(PRIVATE_ROUTES){

        return api.patch(PRIVATE_ROUTES.PRIVACITY_CONFIG,
                         patchBodySnakeCase, 
                         { headers: headersSnakeCase })
            .then((response: any) => {
                return response
            })
            .catch((error: any) => {
                return error.response;
            });
    }
}


export async function getRouteUserGroups(headers: AuthHeaders, userNickname: string, page: string){

    const routeParams={
        userNickname: userNickname,
        page: page
    }

    // const patchBodyMoreInfo = {moreInfo: {patchBody}}

    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: routeParams});

    const headersSnakeCase = convertKeysToSnakeCase(headers)

    if(PRIVATE_ROUTES){

        return api.get(PRIVATE_ROUTES.GET_MEMBER_GROUPS,
                         { headers: headersSnakeCase })
            .then((response: any) => {
                return response
            })
            .catch((error: any) => {
                return error.response;
            });
    }
}

export async function postCreateGroup(headers: any, userNickname: any, postBody: any){

    const routeParams={
        userNickname: userNickname
    }

    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: routeParams});

    const postBodySnakeCase = convertKeysToSnakeCase(postBody)
    const headersSnakeCase = convertKeysToSnakeCase(headers)

    if(PRIVATE_ROUTES){

        return api.post(PRIVATE_ROUTES.GROUPS, 
                        postBodySnakeCase, 
                        { headers: headersSnakeCase })
            .then((response: any) => {
                return response
            })
            .catch((error: any) => {
                return error.response;
            });
    }
}

export async function deleteGroupWithGroupId(headers: any, groupId: any){

    const routeParams={
        groupId: groupId
    }

    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: routeParams});

    const headersSnakeCase = convertKeysToSnakeCase(headers)

    if(PRIVATE_ROUTES){

        return api.delete(PRIVATE_ROUTES.GROUP_DELETE,
                        { headers: headersSnakeCase })
            .then((response: any) => {
                return response
            })
            .catch((error: any) => {
                return error.response;
            });
    }
}

export async function getGroupWithNicknameAndGroupId(headers: AuthHeaders, getGroupRouteParams: GetGroupRouteParams){

    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: getGroupRouteParams});

    const headersSnakeCase = convertKeysToSnakeCase(headers)

    if(PRIVATE_ROUTES){

        return api.get(PRIVATE_ROUTES.GROUP_UPDATE_SHOW,
                        { headers: headersSnakeCase })
            .then((response: any) => {
                return response
            })
            .catch((error: any) => {
                return error.response;
            });
    }
}

export async function createPostWithGroupIdAndUserNickname(headers: AuthHeaders, 
                                                           createPostRouteParams: CreatePostRouteParams,
                                                           postBody: PostCreationBody) {

    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: createPostRouteParams});

    const headersSnakeCase = convertKeysToSnakeCase(headers)
    const postBodySnakeCase = convertKeysToSnakeCase(postBody)

    if(PRIVATE_ROUTES){

        return api.post(PRIVATE_ROUTES.POST_CREATE,
                        postBodySnakeCase,
                        { headers: headersSnakeCase })
            .then((response: any) => {
                return response
            })
            .catch((error: any) => {
                return error.response;
            });
    }
}

export async function getGroupPostsWithGroupIdAndUserNickname(headers: AuthHeaders, getGroupRouteParams: GetGroupPostsRouteParams){

    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: getGroupRouteParams});

    const headersSnakeCase = convertKeysToSnakeCase(headers)

    if(PRIVATE_ROUTES){

        return api.get(PRIVATE_ROUTES.GET_GROUP_POSTS,
                        { headers: headersSnakeCase })
            .then((response: any) => {
                return response
            })
            .catch((error: any) => {
                return error.response;
            });
    }
}

export async function createCommentOnPostWithData(headers: AuthHeaders, routeParams: CreateCommentInPostRouteParams, body: CreatePostBody ){

    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: routeParams});

    const headersSnakeCase = convertKeysToSnakeCase(headers)
    const bodySnakeCase = convertKeysToSnakeCase(body)

    if(PRIVATE_ROUTES){

        return api.post(PRIVATE_ROUTES.CREATE_POST_COMMENT,
                       bodySnakeCase,
                      { headers: headersSnakeCase })
            .then((response: any) => {
                return response
            })
            .catch((error: any) => {
                return error.response;
            });
    }
}

export async function showPostWithComments(headers: AuthHeaders, routeParams: ShowPostWithCommentsRouteParams){

    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: routeParams});

    const headersSnakeCase = convertKeysToSnakeCase(headers)

    if(PRIVATE_ROUTES){

        return api.get(PRIVATE_ROUTES.SHOW_POST_WITH_COMMENTS,
                        { headers: headersSnakeCase })
            .then((response: any) => {
                return response
            })
            .catch((error: any) => {
                return error.response;
            });
    }
}

export async function deleteCommentInPost(headers: AuthHeaders, routeParams: DeletePostCommentRouteParams){

    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: routeParams});

    const headersSnakeCase = convertKeysToSnakeCase(headers)

    if(PRIVATE_ROUTES){

        return api.delete(PRIVATE_ROUTES.DELETE_POST_COMMENT,
                        { headers: headersSnakeCase })
            .then((response: any) => {
                return response
            })
            .catch((error: any) => {
                return error.response;
            });
    }
}

export async function deletePostInGroup(headers: AuthHeaders, routeParams: DeletePostRouteParams){

    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: routeParams});

    const headersSnakeCase = convertKeysToSnakeCase(headers)

    if(PRIVATE_ROUTES){

        return api.delete(PRIVATE_ROUTES.POST_DELETE,
                        { headers: headersSnakeCase })
            .then((response: any) => {
                return response
            })
            .catch((error: any) => {
                return error.response;
            });
    }
}

export async function createProjectContentCommentWithData(headers: AuthHeaders, routeParams: CreateProjectContentCommentRouteParams, body: CreateProjectContentBody ){

    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: routeParams});

    const headersSnakeCase = convertKeysToSnakeCase(headers)
    const bodySnakeCase = convertKeysToSnakeCase(body)

    if(PRIVATE_ROUTES){

        return api.post(PRIVATE_ROUTES.CREATE_PROJECT_CONTENT_COMMENT,
                       bodySnakeCase,
                      { headers: headersSnakeCase })
            .then((response: any) => {
                return response
            })
            .catch((error: any) => {
                return error.response;
            });
    }
}

export async function getProjectContentCommentsWithRequestParams(headers: AuthHeaders, 
                                                                 urlParams: GetProjectContentCommentsUrlParams){

    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: urlParams});

    const headersSnakeCase = convertKeysToSnakeCase(headers)

    if(PRIVATE_ROUTES){

        return api.get(PRIVATE_ROUTES.GET_PROJECT_CONTENT_COMMENTS,
                      { headers: headersSnakeCase })
            .then((response: any) => {
                return response
            })
            .catch((error: any) => {
                return error.response;
            });
    }
}

export async function deleteProjectContentCommentWithRequestParams(headers: AuthHeaders, 
                                                                   urlParams: DeleteProjectContentCommentUrlParams){

        const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: urlParams});

        const headersSnakeCase = convertKeysToSnakeCase(headers)

        if(PRIVATE_ROUTES){

            return api.delete(PRIVATE_ROUTES.DELETE_PROJECT_CONTENT_COMMENT,
                            { headers: headersSnakeCase })
                        .then((response: any) => {
                        return response
                        })
                        .catch((error: any) => {
                        return error.response;
                        });
        }
}

export async function getUserMusicalGenresWithNickname(headers: AuthHeaders, 
                                                       urlParams: GetUserMusicalGenresUrlParams){

        const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: urlParams});

        const headersSnakeCase = convertKeysToSnakeCase(headers)

        if(PRIVATE_ROUTES){

        return api.get(PRIVATE_ROUTES.GET_USER_MUSICAL_GENRES,
                                                              { headers: headersSnakeCase })
                    .then((response: any) => {
                    return response
                    })
                    .catch((error: any) => {
                    return error.response;
                    });
        }
}

export async function patchUserInstrumentWithNicknameAndInstrumentId(headers: AuthHeaders, 
                                                                      urlParams: PatchUserInstrumentUrlParams,
                                                                      requestBodyData: PatchUserInstrumentBodyData){

    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: urlParams});

            const headersSnakeCase = convertKeysToSnakeCase(headers)
            const bodySnakeCase = convertKeysToSnakeCase(requestBodyData)

    if(PRIVATE_ROUTES){

            return api.patch(PRIVATE_ROUTES.PATCH_USER_INSTRUMENT,
                            bodySnakeCase,
                            { headers: headersSnakeCase })
                        .then((response: any) => {
                        return response
                        })
                        .catch((error: any) => {
                        return error.response;
                        });
            }
}

export async function createUserInstrumentWithNicknameAndInstrumentId(headers: AuthHeaders, 
                                                                      urlParams: CreateUserInstrumentUrlParams,
                                                                      requestBodyData: CreateUserInstrumentBodyData){

        const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: urlParams});

        const headersSnakeCase = convertKeysToSnakeCase(headers)
        const bodySnakeCase = convertKeysToSnakeCase(requestBodyData)

        if(PRIVATE_ROUTES){

            return api.post(PRIVATE_ROUTES.CREATE_USER_INSTRUMENT,
                            bodySnakeCase,
                            { headers: headersSnakeCase })
            .then((response: any) => {
            return response
            })
            .catch((error: any) => {
            return error.response;
        });
        }
}

export async function getUserInstrumentWithUserNickname(headers: AuthHeaders, 
                                                        urlParams: GetUserInstrumentUrlParams){

    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: urlParams});

            const headersSnakeCase = convertKeysToSnakeCase(headers)

            if(PRIVATE_ROUTES){

            return api.get(PRIVATE_ROUTES.GET_USER_INSTRUMENTS,
                            { headers: headersSnakeCase })
                        .then((response: any) => {
                        return response
                        })
                        .catch((error: any) => {
                        return error.response;
                        });
            }
}

// export async function patchUserMusicalGenreWithNicknameAndMusicalGenreId(headers: AuthHeaders, 
//                                                                       urlParams: PatchUserMusicalGenreUrlParams,
//                                                                       requestBodyData: PatchUserMusicalGenreBodyData){

//     const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: urlParams});

//             const headersSnakeCase = convertKeysToSnakeCase(headers)
//             const bodySnakeCase = convertKeysToSnakeCase(requestBodyData)

//             if(PRIVATE_ROUTES){

//             return api.patch(PRIVATE_ROUTES.PATCH_USER_MUSICAL_GENRE,
//                             bodySnakeCase,
//                             { headers: headersSnakeCase })
//                         .then((response: any) => {
//                         return response
//                         })
//                         .catch((error: any) => {
//                         return error.response;
//                         });
//             }
// }




export async function patchUserMusicalGenreWithNicknameAndMusicalGenreId(headers: AuthHeaders, 
                                                                         urlParams: PatchUserMusicalGenreUrlParams,
                                                                         requestBodyData: PatchUserMusicalGenreBodyData){

    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: urlParams});

    const headersSnakeCase = convertKeysToSnakeCase(headers)
    const bodySnakeCase = convertKeysToSnakeCase(requestBodyData)

    if(PRIVATE_ROUTES){

        return api.patch(PRIVATE_ROUTES.PATCH_USER_MUSICAL_GENRE,
                        bodySnakeCase,
                        { headers: headersSnakeCase })
                        .then((response: any) => {
        return response
        })
                    .catch((error: any) => {
                    return error.response;
        });
    }
}

export async function createUserMusicalGenreWithNicknameAndInstrumentId(headers: AuthHeaders, 
                                                                        urlParams: CreateUserMusicalGenreUrlParams,
                                                                        requestBodyData: CreateUserMusicalGenreBodyData){

    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: urlParams});

    const headersSnakeCase = convertKeysToSnakeCase(headers)
    const bodySnakeCase = convertKeysToSnakeCase(requestBodyData)

    if(PRIVATE_ROUTES){

    return api.post(PRIVATE_ROUTES.CREATE_USER_MUSICAL_GENRE,
                    bodySnakeCase,
                    { headers: headersSnakeCase })
                .then((response: any) => {
                    return response
                })
                .catch((error: any) => {
                    return error.response;
                });
    }
}





export async function searchResultsWithQueryAndPagination(headers: AuthHeaders, routeParams: GetSearchResultsRouteParams){

    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: routeParams});

    const headersSnakeCase = convertKeysToSnakeCase(headers)

    if(PRIVATE_ROUTES && PRIVATE_ROUTES.GET_SEARCH){
        return api.get(PRIVATE_ROUTES.GET_SEARCH, {headers: headersSnakeCase})
            .then((response: any) => {
                return response
            })
            .catch((error: any) => {
                return error.response;
            });
    }
}

export async function createFellowshipBetweenCurrentAndRouteUser(headers: AuthHeaders, requestBodyData: CreateUserFellowshipRequestBodyData){

    // const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes();

    const headersSnakeCase = convertKeysToSnakeCase(headers)
    const fellowship = convertKeysToSnakeCase(requestBodyData)

    const postBodyObjectSnakeCase = {fellowship}

    return api.post(REQUEST_REQUIREMENTS.CREATE_FELLOWHIP, 
                    postBodyObjectSnakeCase, 
                    { headers: headersSnakeCase })
        .then((response: any) => {
            return response
        })
        .catch((error: any) => {
            return error.response;
        });    
}

export async function getUserFellowsWithUserId(headers: AuthHeaders, 
                                               urlParams: GetCurrentUserFelowsUrlParams){

    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: urlParams});

    const headersSnakeCase = convertKeysToSnakeCase(headers)

    if(PRIVATE_ROUTES){

        return api.get(PRIVATE_ROUTES.GET_CURRENT_USER_FELLOWS,
                    { headers: headersSnakeCase })
                    .then((response: any) => {
                        return response
                    })
                    .catch((error: any) => {
                        return error.response;
                    });
    }
}

export async function patchFellowshipBetweenCurrentAndRouteUser(headers: AuthHeaders, 
                                                                urlParams: PatchUserFellowshipRequestUrlParams,
                                                                patchBody: PatchBodyUserFellowship){

    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: urlParams});

    const headersSnakeCase = convertKeysToSnakeCase(headers)
    const fellowship = convertKeysToSnakeCase(patchBody)

    const patchBodyObjectSnakeCase = {fellowship}

    if(PRIVATE_ROUTES){

        return api.patch(PRIVATE_ROUTES.PATCH_CURRENT_USER_FELLOW,
                         patchBodyObjectSnakeCase,
                        { headers: headersSnakeCase })
                    .then((response: any) => {
                        return response
                    })
                    .catch((error: any) => {
                        return error.response;
                    });
    }
}

export async function getUserNotificationsByPage(headers: AuthHeaders, urlParams: GetUserNotificationsUrlParams){

    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: urlParams});
    const headersSnakeCase = convertKeysToSnakeCase(headers)
    
    return api.get(PRIVATE_ROUTES.GET_CURRENT_USER_NOTIFICATIONS,
                    { headers: headersSnakeCase })
            .then((response: any) => {
    return response
    })
            .catch((error: any) => {
    return error.response;
    });
}

export async function getUserDialoguesByPage(headers: AuthHeaders, urlParams: GetUserDialoguesUrlParams){

    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: urlParams});
    const headersSnakeCase = convertKeysToSnakeCase(headers)
    
    return api.get(PRIVATE_ROUTES.GET_CURRENT_USER_DIALOGUES,
                    { headers: headersSnakeCase })
            .then((response: any) => {
    return response
    })
            .catch((error: any) => {
    return error.response;
    });
}

export async function getUserDialoguesMessagesByPage(headers: AuthHeaders, urlParams: GetUserDialoguesUrlParams){

    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: urlParams});
    const headersSnakeCase = convertKeysToSnakeCase(headers)
    
    return api.get(PRIVATE_ROUTES.GET_CURRENT_USER_DIALOGUES_MESSAGES,
                    { headers: headersSnakeCase })
              .then((response: any) => {
                return response
              })
              .catch((error: any) => {
                return error.response;
              });
}

export async function createUserDialogueWithUserNickname(headers: AuthHeaders, urlParams: PostUserDialogueUrlParams, body: PostUserDialogueBody){

    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: urlParams});

    const headersSnakeCase = convertKeysToSnakeCase(headers)
    const dialogueMessages = convertKeysToSnakeCase(body)

    const postBodyObjectSnakeCase = convertKeysToSnakeCase(dialogueMessages)

    return api.post(PRIVATE_ROUTES.CREATE_USER_DIALOGUE, 
                    postBodyObjectSnakeCase, 
                    { headers: headersSnakeCase })
        .then((response: any) => {
            return response
        })
        .catch((error: any) => {
            return error.response;
        });    
}

export async function sendRedefinitionMail(){

    // const routeParams = {
    //     propertyToSearch: propertyToSearch,
    //     searchTerm: fieldInput
    // }

    // const headersSnakeCase = convertKeysToSnakeCase(headers)

    console.log("export async function sendRedefinitionMail(){ >>>>>>>>>>> ")

    // const data = {
    //         email: 'filipedasilva94.1@gmail.com',
    //         redirect_url: REQUEST_REQUIREMENTS.HOME_ENDPOINT
    // };

    // return api.post(REQUEST_REQUIREMENTS.REDEFINITION_MAIL,data)
    //     .then((response: any) => {
    //         return response
    //     })
    //     .catch((error: any) => {
    //         return error.response;
    //     });
}

function convertKeysToSnakeCase(obj: any): any {
    if (typeof obj === 'object' && obj !== null) {
      if (Array.isArray(obj)) {
        return obj.map(item => convertKeysToSnakeCase(item));
      } else {
        if (obj.constructor === Object) {
          const newObj: { [key: string]: any } = {}; // Novo objeto tipado
          for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
              const snakeCaseKey =
                key === 'accessToken' ? 'access-token' : key.replace(/([A-Z])/g, (match) => `_${match.toLowerCase()}`);
              newObj[snakeCaseKey] = convertKeysToSnakeCase(obj[key]);
            }
          }
          return newObj;
        }
      }
    }
    return obj;
  }