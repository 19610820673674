import React from 'react';
import { Margin, MarginOutlined, Visibility, VisibilityOff } from '@mui/icons-material';
import { Alert, Box, Button, Card, CardActions, CardContent, Container, Divider,
    FormControl, FormGroup, IconButton, Input, InputAdornment, InputLabel, 
    Typography,FormHelperText} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { signUpUser } from '../../../app/features/sessions/sessionSlice';
import { AppDispatch, RootState } from '../../../app/store';
import { FormControlEdited, LinkEdited } from "../../../app/features/sessions/EditedTemplate";
import jinnerLogo from '../../../app/assets/jinner-logo-red.png';
import LoadingAnimation from '../../../app/components/modal/LoadingAnimation';
import { closeAllDialogueBoxes } from '../../../app/features/sessions/sessionDialogues';

const theme = createTheme({
    palette: {
        primary: {
            main: '#A52A2A'
        }
    }
});

function SignUp() {
    const nicknameRef = useRef<HTMLInputElement>();
    const emailRef = useRef<HTMLInputElement>();
    const passwordRef = useRef<HTMLInputElement>();
    const passwordConfirmationRef = useRef<HTMLInputElement>();
    
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const [successMessages, setSuccessMessages] = useState<string[]>([]);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const loading = useSelector((state: RootState) => state.session.loading);
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        if (nicknameRef?.current === undefined
            || emailRef?.current === undefined
            || passwordRef?.current === undefined
            || passwordConfirmationRef?.current === undefined) {
            setErrorMessages(["Please fill out all fields"]);
            return;
        }

        const payload = {
            nickname: nicknameRef.current.value,
            email: emailRef.current.value,
            password: passwordRef.current.value,
            passwordConfirmation: passwordConfirmationRef.current.value
        }

        const response = await dispatch(signUpUser(payload));

        if (response.payload.errors) {
            setSuccessMessages([]);

            setErrorMessages(response.payload.errors.full_messages);
        } else if (response.payload.status === "success") {
            setErrorMessages([]);
            setSuccessMessages([response.payload.status]);
        }
    }

    const passwordInput = (
        <Input 
            id='password' 
            type={showPassword ? 'text' : 'password'}
            inputRef={passwordRef}
            endAdornment={
                <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={() => setShowPassword(!showPassword)}
                        edge="end"> 
                        { showPassword ? <Visibility /> : <VisibilityOff/> }
                    </IconButton>
                </InputAdornment>
            }
        />
    );    

    const passwordConfirmationInput = (
        <Input 
            id='password-confirmation' 
            type={showPassword ? 'text' : 'password'}
            inputRef={passwordConfirmationRef}
            endAdornment={
                <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={() => setShowPassword(!showPassword)}
                        edge="end"> 
                        { showPassword ? <Visibility /> : <VisibilityOff/> }
                    </IconButton>
                </InputAdornment>
            }
        />
    );

    return (
        <section style={{marginTop:"5em", marginBottom: "2em", fontFamily: 'sans-serif'}}>
            <Container maxWidth="sm" sx={{ display: 'relative', justifyContent: 'center', fontFamily:'Calibri'}}>
                <Card sx={{boxShadow:1, maxWidth: 'md', fontFamily:'Calibri'}}>
                    <CardContent>
                        <Box sx={{ display: 'flex', justifyContent: 'center', fontFamily:'Calibri' }}>
                            <img
                                src={jinnerLogo}
                                alt="Logo"
                                style={{ width: '100px' }}
                            />
                        </Box>
                        <Container maxWidth="sm">
                            {loading ?
                                <Box sx={{ display: 'flex', height: '100px'}}>
                                    <LoadingAnimation/>
                                </Box> 
                            : errorMessages.length > 0 ?
                                <Alert severity="error" aria-live="assertive">
                                    {errorMessages.map((error, index) => (
                                        <span key={`alert-error-${index}`} style={{ color: 'red', display: 'block' }}>
                                            {`${error}`}
                                        </span>
                                    ))}    
                                </Alert>
                            : successMessages.length > 0 ? (
                                <Alert severity="success" aria-live="assertive" style={{ color: 'green' }}>
                                    {successMessages}
                                </Alert>
                            ) : <></>}
                            <Typography variant="h5" color="text.primary" gutterBottom sx={{marginTop: '10px',fontFamily: 'sans-serif'}}>
                                Sign Up
                            </Typography>
                            <form onSubmit={handleSubmit}>
                                <FormGroup row={true} id="nickname-group" sx={{marginTop: "1em"}}>
                                    <FormControlEdited fullWidth>
                                        <InputLabel required htmlFor="nickname" id="nickname-label">Nickname</InputLabel>
                                        <Input id="nickname" type="text" inputRef={nicknameRef}/>
                                    </FormControlEdited>
                                </FormGroup>
                                <FormGroup row={true} id="email-group" sx={{marginTop: "1em"}}>
                                    <FormControlEdited fullWidth>
                                        <InputLabel required htmlFor="email" id="email-label">Email Address</InputLabel>
                                        <Input id="email" type="email" inputRef={emailRef}/>
                                        <FormHelperText id="email-helper-text">We&apos;ll never share your email.</FormHelperText>
                                    </FormControlEdited>
                                </FormGroup>
                                <FormGroup row={true} id="password-group" sx={{marginTop: "1em"}}>
                                    <FormControlEdited fullWidth>
                                        <InputLabel required htmlFor="password" id="password-label">Password</InputLabel>
                                        {passwordInput}
                                    </FormControlEdited>
                                </FormGroup>
                                <FormGroup row={true} id="password-confirmation-group" sx={{marginTop: "1em"}}>
                                    <FormControlEdited fullWidth>
                                        <InputLabel required htmlFor="password-confirmation" id="password-confirmation-label">Password Confirmation</InputLabel>
                                        {passwordConfirmationInput}
                                    </FormControlEdited>
                                </FormGroup>
                                <FormGroup row={true} id="submit-group" sx={{marginTop: "1em"}}>
                                    <ThemeProvider theme={theme}>
                                        <FormControlEdited fullWidth>
                                            <Button
                                                disabled={loading}
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                id="submit-button">Create Account
                                            </Button>
                                        </FormControlEdited>
                                    </ThemeProvider>
                                </FormGroup>
                            </form>
                        </Container>
                    </CardContent>
                    <Divider light={false}/>
                    <CardActions sx={{ justifyContent:'center'}} disableSpacing >
                        <Box
                            sx={{
                                fontFamily: 'Calibri, sans-serif',
                            }}
                        >
                            Already have an account? 
                            <LinkEdited 
                                sx={{
                                     marginLeft: '5px',
                                }}
                                to="/auth/sign_in">
                                    Sign in
                            </LinkEdited>
                        </Box>
                    </CardActions>
                </Card>
            </Container>
        </section>
    );
}

export default SignUp;