import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './profile.css';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../app/store';
import { getGenericUserInfo, getUserContents, getRouteProjects, getUserMusicalGenres, getUserInstrumentsProfile, createUserFellowship, getCurrentUserFelows, patchUserFellowship} from '../../../../app/features/sessions/sessionProfileNavigation';
import defaultProfileImage from '../../../../app/assets/profile-default-red.png';
import editIcon from '../../../app/assets/edit-icon-white.png';
import fellowshipIconWhite from '../../../../app/assets/brotherhood-icon-white.png';
import manIconWhite from '../../../../app/assets/man-icon-white.png';
import messageIconWhite from '../../../../app/assets/message-icon-white.png';
import GenresInfluencesModal from '../../../../app/components/modal/profileModals/musicalGenres/UserMusicalGenresModal';
import { ContentPlayer } from '../../../../app/components/audioPlayer/WaveSurfer/contentPlayer/ContentPlayer';
import { ProjectPlayer } from '../../../../app/components/audioPlayer/WaveSurfer/projectPlayer/ProjectPlayer';
import moreDetailsIcon from '../../../../app/assets/more-details-white.png';
import defaultCover from '../../../../app/assets/default-cover.jpeg';
import { format } from 'date-fns';
import visitorsIcon from '../../../../app/assets/visitors.png';
import ProjectDetailsModal from '../../../../app/components/modal/projectModal/ProjectDetails';
import AddInfluencesModal from '../../../../app/components/modal/profileModals/musicalGenres/AddRemoveUserMusicalGenres';
import AddGenreInfluencesModal from '../../../../app/components/modal/profileModals/musicalGenres/AddRemoveUserMusicalGenres';
import UserMusicalGenresModal from '../../../../app/components/modal/profileModals/musicalGenres/UserMusicalGenresModal';
import AddRemoveUserInstruments from '../../../../app/components/modal/profileModals/userInstruments/AddRemoveUserInstruments';
import UserInstrumentsModal from '../../../../app/components/modal/profileModals/userInstruments/UserInstrumentsModal';
import AddRemoveUserMusicalGenres from '../../../../app/components/modal/profileModals/musicalGenres/AddRemoveUserMusicalGenres';
import { Fellowship, UserInstrument, UserMusicalGenre } from '../../../../app/#interfaces/interfaces';
import LoadingModal from '../../../../app/components/modal/loadingModal/LoadingModal';
import { CircularProgress } from '@mui/material';
import { selectDialogue } from '../../../../app/features/sessions/sessionDialogues';
import PhoneIcon from '../../../../app/assets/phone-icon.png';
import MailIcon from '../../../../app/assets/mail-icon.png';
import * as REQUEST_REQUIREMENTS from '../../../../app/api/requestRequirements'

export default function Profile(){
    const currentUser = useSelector((state: RootState) => state.session.currentUser);
    const authHeaders = useSelector((state: RootState) => state.session.authHeaders);
    const dispatch = useDispatch<AppDispatch>();
    const [fellowship,setFellowship] = useState(false)
    const userProfileRoute = useSelector((state: RootState) => state.sessionProfile.userMoreInfo); 
    const visibleItemsMusicGenres = 7;
    const visibleItemsInstruments = 7;
    const userMusicalGenres = useSelector((state: RootState) => 
        state.sessionProfile.userMusicalGenres.filter((userMusicGenre: UserMusicalGenre) => userMusicGenre.selected === true)
      );
    const userInstruments = useSelector((state: RootState) => 
        state.sessionProfile.userInstruments.filter((userInstrument: UserInstrument) => userInstrument.selected === true)
      );
    const remainingItemsMusicGenres = userMusicalGenres.length - visibleItemsMusicGenres;
    const remainingItemsInstruments = userInstruments.length - visibleItemsInstruments;
    const [musicalInflecesModalOpen, setMusicalInflecesModalOpen] = useState(false);
    const [musicalGenresModalOpen, setMusicalGenresModalOpen] = useState(false);
    const [listShowDetails, setListShowDetails] = useState<string[]>();
    const [modalDescription, setModalDescription] = useState('');
    const [contentsClicked, setContentsClicked] = useState(false)
    const userContents = useSelector((state: RootState) => state.sessionProfile.userContents);
    const userProjects = useSelector((state: RootState) => state.sessionProfile.userProjects);
    const userMoreInfo = useSelector((state: RootState) => state.sessionProfile.userMoreInfo);
    const [toggleModalDescriptionOpen, setToggleModalDescriptionOpen ] = useState(false);
    const [projectSelected, setProjectSelected] = useState();
    const [toggleModalAddProperty, setToggleModalAddProperty ] = useState(false)
    const [showPropertyInAddModel, setShowPropertyInAddModel] = useState<string[]>()
    const [addModalDescription, setAddModalDescription] = useState('');
    const MemorizedContentPlayer = useMemo(() => React.memo(ContentPlayer), []);
    const MemorizedProjectPlayer = useMemo(() => React.memo(ProjectPlayer), []);
    const { userNicknameRoute } = useParams<string>();
    const [showAddRemoveInstrumentsModal, setShowAddRemoveInstrumentsModal] = useState(false);
    const [showAddRemoveMusicalGenresModal, setShowAddRemoveMusicalGenresModal] = useState(false);
    const [showUserInstrumentsModal, setShowUserInstrumentsModal] = useState(false);
    const loadingProfilePage = useSelector((state: RootState) => state.sessionProfile.loadingProfilePage);
    const [isFetching, setIsFetching] = useState(false);
    const [contentsPage, setContentsPage] = useState(1);
    const loadingMoreUserContents = useSelector((state: RootState) => state.sessionProfile.loadingMoreUserContents);
    const loadingMoreUserProjects = useSelector((state: RootState) => state.sessionProfile.loadingMoreUserProject);
    const [projectsPage, setProjectPage] = useState<number>(1);
    const userRouteIsFellow = useSelector((state: RootState) =>
        userNicknameRoute
            ? state.sessionProfile.currentUserFellows.some(
                  (feloowship: Fellowship) => 
                    feloowship.fellowUser?.userMoreInfo?.nickname === userNicknameRoute
              )
            : false
    );
    const selectedDialogues = useSelector((state: RootState) => state.sessionDialogues.selectedDialogues);
    const navigate = useNavigate();

    const userRouteIsActiveFellow = useSelector((state: RootState) =>
        userNicknameRoute
            ? state.sessionProfile.currentUserFellows.some(
                  (fellowship: Fellowship) =>
                    fellowship.fellowUser?.userMoreInfo?.nickname === userNicknameRoute &&
                    fellowship.active === true
              )
            : false
    );



    const whiteButtonStyle = {
        backgroundColor: 'white',
        color: 'black',
    };
    
    const grayButtonStyle = {
        backgroundColor: '#ddd',
        color: '#666',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease'    
    };
    
    const hoverGrayButtonStyle = {
        backgroundColor: '#bbb'
    };

    useEffect(()=>{
        async function getUserProfile(){
            if (authHeaders && userNicknameRoute) {
                const response = await dispatch(getGenericUserInfo({authHeaders: authHeaders, userNickname: userNicknameRoute}))

                if(response.meta.requestStatus !== "fulfilled"){

                    const routeParams = {
                        userNickname: currentUser?.nickname
                    }
                
                    const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: routeParams});

                    navigate(PRIVATE_ROUTES.USER)
                }
            }
        }

        async function fetchRouteContents() {
            if (authHeaders && userNicknameRoute) {
                await dispatch(getUserContents({authHeaders: authHeaders, 
                                                routeParams: {
                                                    userNickname: userNicknameRoute,
                                                    contentsPage: contentsPage
                                                }}))
            }
        }

        function handleRouteProjects(){
            if (authHeaders && userNicknameRoute) {
                const response = dispatch(getRouteProjects({authHeaders: authHeaders, 
                                                                urlParams:{
                                                                    userNickname: userNicknameRoute,
                                                                    page: projectsPage}}))
            }
        }

        function getUserRouteMusicalGenres(){
            if (authHeaders && userNicknameRoute){
                const response = dispatch(getUserMusicalGenres({headers: authHeaders, 
                                                                urlParams: {
                                                                    userNickname: userNicknameRoute}
                                                                }
                                                              )
                                         )
            }
        }

        async function fetchUserInstruments() {
            if (authHeaders && userNicknameRoute){
                const response = await dispatch(getUserInstrumentsProfile({
                                                                    headers: authHeaders,
                                                                    urlParams:{
                                                                        userNickname: userNicknameRoute
                                                                    }
                                                                   }
                                                                  )
                                                                )
                                                            }
                                                                
                                                        }
        async function fetchCurrentUserFelows(){
            if (currentUser && currentUser.id && authHeaders){
                const response = dispatch(getCurrentUserFelows({authHeaders: authHeaders, 
                                                                urlParams: {
                                                                    currentUserId: currentUser.id
                                                                }
                                                              }))
            }
        }
        
        fetchUserInstruments();
        getUserRouteMusicalGenres();
        handleRouteProjects();
        fetchRouteContents();
        getUserProfile();
        fetchCurrentUserFelows();
        
    },[userNicknameRoute])

    useEffect(()=>{

        async function fetchContents(){
            if (authHeaders){
                await dispatch(getUserContents({
                    authHeaders: authHeaders,
                    routeParams: {
                        userNickname: userNicknameRoute,
                        contentsPage: contentsPage
                    }
                }));
                setIsFetching(false);
            }
        }

        fetchContents();

    },[contentsPage])

    useEffect(() => {
        function handleScroll() {
            if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight 
                || loadingMoreUserContents) return;
            setContentsPage(contentsPage + 1);
        }

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [loadingMoreUserContents]);


    function handleShowOption(option: number){

        if (option == 1){
            setContentsClicked(!contentsClicked)
        }
    }

    function handleFormatDate(date:any){
        const originalDate = new Date(date);
        const formattedDate = format(originalDate, 'dd MMM yyyy HH:mm:ss');
        return formattedDate
    }

    function toggleDescriptionModal(projectSelected: any){
        setToggleModalDescriptionOpen(!toggleModalDescriptionOpen);
        setProjectSelected(projectSelected)
    }

    async function handleSpotifyGenresSearch() {
        console.log("handleSpotifyGenresSearch clicked >>>>>>>>>>>> ")
    }

    async function handleFellowship({ nickname }: { nickname: string | undefined }) {

        if (authHeaders && !userRouteIsFellow){
            const response = dispatch(createUserFellowship({authHeaders: authHeaders,
                                                            requestBodyData:{
                                                                profileNickname: nickname
                                                            }}))

        } else if (authHeaders && userRouteIsFellow && currentUser) {
            const response = dispatch(patchUserFellowship({authHeaders: authHeaders,
                                                            urlParams:{
                                                                profileNickname: nickname,
                                                                currentUserId: currentUser.id
                                                            },
                                                            patchBody: {
                                                                active: !userRouteIsActiveFellow
                                                            }
                                                        }))
            }
        }

    async function handleOpenNewDialogue(userDialogue: string | undefined) {

        try {
            
            if (userDialogue && !selectedDialogues.includes(userDialogue)) {
                return dispatch(selectDialogue({ nickname: userDialogue }));
            } else {
                return
            }

        } catch (error) {
            console.error(error);
        }
    }


    return(
        
        <>
        
            {loadingProfilePage ?
             <LoadingModal/>
             :

            <div id='container-profile'>
                <div id='container-center'>
                    <div id='container-top' style={{backgroundImage: `url(${defaultCover})`, 
                                                    backgroundSize: 'cover',
                                                    backgroundRepeat: 'no-repeat',
                                                    backgroundPosition: 'bottom'}}>
                        <div id='container-top-cover'>
                            {
                                userMoreInfo.name !== '' ||
                                userMoreInfo.lastName !== '' ||
                                userMoreInfo.about !== '' ||
                                userMoreInfo.phoneNumber !== '' ||
                                (userMoreInfo.city !== '' &&
                                userMoreInfo.state !== '') ||
                                userMoreInfo.country !== ''

                                ?


                                <div id='container-user-more-info-cover'>

                                    {
                                        userMoreInfo.name !== '' ||
                                        userMoreInfo.lastName !== '' 

                                        ?

                                        <div id='container-complete-name'>
                                            <h2>{userMoreInfo.name}&nbsp;{userMoreInfo.lastName}</h2>
                                        </div>

                                        :

                                        null
                                    }
                                    {
                                        userMoreInfo.about !== '' 

                                        ?

                                        <div id='container-about'>
                                            <h2>{userMoreInfo.about}</h2>
                                        </div>

                                        :

                                        null

                                    }
                                    {
                                        userMoreInfo.phoneNumber !== ''
                        
                                        ?

                                        <div id='container-contact'>
                                            <img src={PhoneIcon}/>
                                            <h2>{userMoreInfo.phoneNumber}</h2>
                                            <img src={MailIcon}/>
                                            <h2>{userMoreInfo.email}</h2>
                                        </div>
                                    
                                        : 

                                        null

                                    }
                                    {
                                        userMoreInfo.city !== '' && 
                                        userMoreInfo.state !== '' && 
                                        userMoreInfo.country !== ''
                                    
                                        ?

                                        <div id='container-location'>
                                            <h2>{userMoreInfo.city},&nbsp;</h2>
                                            <h2>{userMoreInfo.state}&nbsp;-&nbsp;</h2>
                                            <h2>{userMoreInfo.country}</h2>
                                        </div>
                                        
                                        :

                                        null
                                    }

                                    {
                                        userMoreInfo.website !== '' 
                                    
                                        ?

                                        <div id='container-website'>
                                            <a href={userMoreInfo.website.startsWith("http") ? userMoreInfo.website : `https://${userMoreInfo.website}`} 
                                                target="_blank" 
                                                rel="noopener noreferrer"
                                            >
                                                {userMoreInfo.website}
                                            </a>
                                        </div>
                                        
                                        :

                                        null
                                    }



                                </div>
                                :
                                null
                            }
                            
                            <div id='container-user-avater-nickname'>

                                <div id='container-profile-image-top'>
                                    
                                    {userMoreInfo.avatarUrl ? 
                                        <img src={userMoreInfo.avatarUrl}/>
                                        :
                                        <img src={defaultProfileImage}/>
                                    }
                                </div>
                                
                                <div id='container-profile-title'>
                                    
                                    <div>
                                        <h2>@{userProfileRoute?.nickname}</h2>
                                    </div>
                                    <div id='container-name-last-name-profile'>
                                        <h3>{userProfileRoute?.name} {" "} {userProfileRoute?.lastName}</h3>
                                    </div>

                                </div>
                            </div>

                                {userNicknameRoute != currentUser?.nickname && currentUser != undefined ?
                                <div id='container-profile-buttons'>
                                    <button id='button-fellowship-profile'
                                            onClick={()=> handleFellowship({ nickname: userNicknameRoute })}>
                                        { userRouteIsActiveFellow === true ?
                                        <div>
                                            <img src={fellowshipIconWhite} style={{ transform: 'translateX(10px)' }}/> 
                                        </div>
                                        :
                                        <div>
                                            <img src={manIconWhite} style={{ position: 'relative', right: '5px'  }}/>
                                            <img src={manIconWhite} style={{ position: 'relative', right: '20px' }}/>
                                        </div>
                                        }
                                        <h4>Fellowship</h4>
                                    </button>
                                    <button id='button-send-message' onClick={() => handleOpenNewDialogue(userNicknameRoute)}>
                                        <div id='container-send-message-icon'>
                                            <img style={{marginRight:'15px'}} src={messageIconWhite}/> 
                                        </div>
                                        <h4>Messege</h4>
                                    </button>
                                </div>
                                :
                                null
                                }
                            
                        </div>
                        <div id='container-references-top'>
                            <div id='container-user-instruments-top-profile'>
                                <h3>Instruments</h3>
                                <ul>
                                    {userInstruments && userInstruments.length > 0 && userInstruments.slice(0, visibleItemsInstruments).map((item: UserInstrument, index) => (
                                        <li key={index}>
                                            <h6>{item.instrument?.name}</h6>
                                        </li>
                                    ))}
                                    {remainingItemsInstruments > 0 && (
                                        <li id='remaining-user-instruments-profile' key="remaining" >
                                            <h6 onClick={() => {setShowUserInstrumentsModal(true)}}>
                                                {`+${remainingItemsInstruments}`}
                                            </h6>
                                        </li>
                                    )}
                                    {userNicknameRoute == currentUser?.nickname?
                                    <li id='li-add-user-instruments-profile' >
                                        <h6 onClick={() => {setShowAddRemoveInstrumentsModal(true)}}>
                                            add/remove
                                        </h6>
                                    </li>
                                    :
                                    null}
                                </ul>
                            </div>
                            <div id='container-music-genres-top-profile'>
                                <h3>Musical genres</h3>
                                <ul>
                                    {userMusicalGenres && userMusicalGenres.slice(0, visibleItemsMusicGenres).map((userMusicalGenre: UserMusicalGenre, index) => (
                                        <li key={index}>
                                            <h6>{userMusicalGenre.musicGenre.description}</h6>
                                        </li>
                                    ))}
                                    {remainingItemsMusicGenres > 0 && (
                                        <li id='remaining-music-genres-profile' key="remaining" >
                                            <h6 onClick={() => {setMusicalGenresModalOpen(true)}}>
                                                {`+${remainingItemsMusicGenres}`}
                                            </h6>
                                        </li>
                                    )}
                                    {userNicknameRoute == currentUser?.nickname?
                                    <li id='li-add-music-genres-profile' >
                                        <h6 onClick={() => {setShowAddRemoveMusicalGenresModal(true)}}>
                                            add/remove
                                        </h6>
                                    </li>
                                    :
                                    null}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div id='container-roll'>
                        <div id='container-profile-contents'>
                            {/* <button id='button-contents-profile'
                                    style={{...grayButtonStyle, ...( contentsClicked ? whiteButtonStyle : hoverGrayButtonStyle)}}
                                    disabled={contentsClicked}
                                    value={1}
                                    onClick={() => {handleShowOption(1)}}
                                    >
                                    Contents
                            </button> */}
                            <button id='button-projects-profile'
                                    style={{...grayButtonStyle, ...( contentsClicked ? hoverGrayButtonStyle : whiteButtonStyle)}}
                                    disabled={!contentsClicked}
                                    onClick={() => {handleShowOption(1)}}
                                    >
                                    Projects
                            </button>
                        </div>
                        <div id='container-ul-user-contents-projects'>
                        {contentsClicked ? (
                            <ul>
                                {userContents && userContents.length > 0 && userContents.map((item, index) => (
                                    <li key={index}>
                                        <div id='container-title-content-profile'>
                                            <h5 className='title-content-profile-h5'>{item.title}</h5>
                                        </div>
                                        <div id='container-wave-surfer-player'>
                                            <MemorizedContentPlayer fileUrl={`${item.fileUrl}`} />
                                        </div>
                                    </li>
                                ))}
                                
                                {loadingMoreUserContents && (
                                    <div id='container-loading-spineer'>
                                        <CircularProgress color='secondary' sx={{ color: 'brown' }} />
                                    </div>
                                )}
                            
                                {!loadingMoreUserContents && (!userContents || userContents.length === 0) && (
                                    <p>Nothing to Show</p>
                                )}
                            </ul>
                        
                                ) : (
                                    <ul>
                                        { loadingMoreUserProjects
                                        
                                        ?
                
                                        <div id='container-loading-spineer'>
                                            <CircularProgress color='secondary' sx={{ color: 'brown' }} />
                                        </div>
        
                                        :
        
                                        null
        
                                        }
                                        {userProjects && userProjects.length == 0 && !loadingMoreUserContents ? (
                                            <p>Nothing to Show</p>
                                        ) : (
                                            userProjects.map((item, index) => (
                                                <li key={index}>
                                                    <div id='container-project-profile'>
                                                        <div id='container-top-profile-project-line'>
                                                            <div id='container-details-project-profile' style={{whiteSpace: 'nowrap', display:'flex', flexDirection:'row', marginLeft:'5px'}}>
                                                                <h3>{item.project.title}</h3>
                                                                <div className='container-visitors' style={{display: 'flex', flexDirection: 'row', alignItems:'center', marginLeft:'20px'}}>
                                                                    <img style={{ height: '20px' }} src={visitorsIcon} />
                                                                    <h4 style={{margin:'0px'}}>{item.project.nVisits}</h4>
                                                                </div>  
                                                            </div>
                                                            <div id='container-details-button-project-profile' style={{display:'flex', flexDirection:'row',width:'100%', justifyContent:'right'}}>

                                                                <div id='container-another-project-profile-details' style={{marginLeft:'5px'}}>
                                                                    <div className='container-usability-project-profile' style={{display:'flex', flexDirection:'row'}}>
                                                                        <label style={{fontWeight:'bold'}}>Useful: </label>
                                                                        <h4>{item.project.nUsefulContents}</h4>
                                                                    </div>
                                                                    <div className='container-usability-project-profile' style={{display:'flex', flexDirection:'row'}}>
                                                                        <label style={{fontWeight:'bold'}}>Useless: </label>
                                                                        <h4>{item.project.nUselessContents}</h4>
                                                                    </div>
                                                                </div>
                                                                <div id='container-another-project-profile-details' style={{marginLeft:'20px'}}>
                                                                    <div id='container-dates-project-profile'>
                                                                        <label style={{fontWeight:'bold'}}>Created at: </label>
                                                                        <h4>{handleFormatDate(item.project.createdAt)}</h4>
                                                                    </div>
                                                                    <div id='container-dates-project-profile'>
                                                                        <label style={{fontWeight:'bold'}}>Updated at: </label>
                                                                        <h4>{handleFormatDate(item.project.updatedAt)}</h4>
                                                                    </div>
                                                                </div>
                                                                <div id='container-access-project-buttons' style={{marginLeft:'5px', marginRight:'5px'}}>
                                                                    <button id='button-more-details-project-profile' onClick={() => {toggleDescriptionModal(item)}} >
                                                                        <img src={moreDetailsIcon}/>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div id='container-player-project-profile'>
                                                            {item.masterFiles ?
                                                                <MemorizedProjectPlayer
                                                                    fileUrl={`${item.masterFiles[item.masterFiles.length - 1].signedUrl}`}/>
                                                                : null}
                                                        </div>
                                                    </div>
                                                </li>
                                            ))
                                        )}

                                        { loadingMoreUserContents
                                        
                                        ?
                
                                        <div id='container-loading-spineer'>
                                            <CircularProgress color='secondary' sx={{ color: 'brown' }} />
                                        </div>

                                        :

                                        null

                                        }
                                    </ul>
                                )}
                        </div>
                    </div>
                </div>


                {/* {userInstrumentsModalOpen == true ?
                    <UserMusicalGenresModal 
                    userId={userNicknameRoute}
                        onClose={setMusicalGenresModalOpen}/>
                :

                null} */}

                {
                 showAddRemoveInstrumentsModal == true ?
                 <AddRemoveUserInstruments
                 userNickname={userNicknameRoute}
                 onClose={setShowAddRemoveInstrumentsModal}
                 />
                 :

                 null    
                }

                {
                    showAddRemoveMusicalGenresModal == true ?
                    <AddRemoveUserMusicalGenres
                    userNickname={userNicknameRoute}
                    onClose={setShowAddRemoveMusicalGenresModal}
                    />
                    :

                 null    
                }

                {
                    showUserInstrumentsModal == true ?
                    <UserInstrumentsModal
                    userNickname={userNicknameRoute}
                    onClose={setShowUserInstrumentsModal}
                    />
                 :

                 null    
                }

                {musicalGenresModalOpen == true ?
                    <UserMusicalGenresModal 
                    userId={userNicknameRoute}
                    onClose={setMusicalGenresModalOpen}/>
                    :
                
                null}

                {toggleModalDescriptionOpen == true ?
                    <ProjectDetailsModal
                    projectInfoAndUrls={projectSelected}
                    close={ () => setToggleModalDescriptionOpen(!toggleModalDescriptionOpen) }
                    />:
                null}


                {toggleModalAddProperty == true?
                    <AddGenreInfluencesModal
                    GetDeezerData={handleSpotifyGenresSearch}
                    closeModal={() => {setToggleModalAddProperty(!toggleModalAddProperty)}}
                    />
                    :
                null}

            </div>
        }
        </>
    )
}