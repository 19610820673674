import LogoWithSubtitle from '../../../app/assets/logo-red-name-red-subtitle-black-portrait.png';
import SupposedUser1 from '../../../app/assets/guy-musician-landing-page.png';
import SupposedUser2 from '../../../app/assets/morroccan-singer-girl.png';
import SupposedUser3 from '../../../app/assets/rocker-guy.png';
import ArrowIcon from '../../../app/assets/slide-up-icon.png';
import FirstStepTutorial from '../../../app/assets/first-step-tutorial.png';
import SecondStepTutorial from '../../../app/assets/second-step-tutorial.png';
import ThirdStepTutorial from '../../../app/assets/third-step-tutorial.png';
import FourthStepTutorial from '../../../app/assets/fourth-step-tutorial.png';
import FifthStepTutorial from '../../../app/assets/fifth-step-tutorial.png';
import LogoRedWithTitleRed from '../../../app/assets/logo-red-name-red-whithout-sub-backgound-white-portrait.jpeg';
import PhoneIcon from '../../../app/assets/phone-icon.png';
import MailIcon from '../../../app/assets/mail-icon.png';
import './landingPage.css';
import { useEffect, useState } from 'react';
import { RootState } from '../../../app/store';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as REQUEST_REQUIREMENTS from '../../../../src/app/api/requestRequirements';
import PrivateRoute from '../../../routes/PrivateRoute';

export default function LandingPage(){
    const [tutorialShow, setTutorialShow] = useState(false);
    const currentUser = useSelector((state: RootState) => state.session.currentUser);
    const navigate = useNavigate();

    useEffect(()=> {

        if (currentUser){

            try{

                const PRIVATE_ROUTES =  REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: {userNickname: currentUser.nickname}})
            
                navigate(PRIVATE_ROUTES.USER)
            }
            catch{
                console.warn("Cannot find pricate routes in lading page component")
            }
        
            
        }

    },[])

    return(
        <div id='container-landing-page'>
            <div id='session-1'>

                <div id='container-session-1-sub-1'>
                    <div id='container-text-session-1-sub-1'>
                        <div id='text-top-session-1-sub-1'>
                            <h1>
                                CO-CREATE SONGS
                            </h1>
                            <h2>
                                with another users 
                            </h2>
                        </div>
                        <h3>
                            A platform made by amateurs, 
                            for amateurs, to amateurs.
                        </h3>
                    </div>
                    <img src={LogoWithSubtitle}/>

                </div>

            </div>
            <div id='session-2'>
                <div id='container-cases'>
                    <div id='container-case-1'>
                        <div id='container-bar-text-pic'>
                            <div id='container-case-text'>

                                <div id='container-depoiment'>
                                    <h4 id='h4-depoiment'>
                                        “I was tired to see a lot of ‘beautiful people’ 
                                        getting a lot of followers on social medias. 
                                        Where is the music?”
                                    </h4>
                                    <h4 id='h4-deponent-name'>
                                        - Joseph, Los Angeles - CA - U.S.A
                                    </h4>
                                </div>
                            </div>

                            <img src={SupposedUser1}/>

                        </div>
                    </div>
                    <div id='container-case-2'>
                        <div id='container-bar-text-pic'>
                            
                            <img src={SupposedUser2}/>
                            
                            <div id='container-case-text'>
                                <div id='container-depoiment'>
                                    <h4 id='h4-depoiment'>
                                        “Oh... I don’t like to expose myself when I sing.
                                        I’m very shy.”
                                    </h4>
                                    <h4 id='h4-deponent-name'>
                                        - Gisele, Rabat - Morroco
                                    </h4>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div id='container-case-3'>
                        <div id='container-bar-text-pic'>
                            
                            <div id='container-case-text'>
                                <div id='container-depoiment'>
                                    <h4 id='h4-depoiment'>
                                        “Music is not so good today, you know?”
                                    </h4>
                                    <h4 id='h4-deponent-name'>
                                        - Alex - Russia
                                    </h4>
                                </div>
                            </div>

                            <img src={SupposedUser3}/>
                            
                        </div>
                    </div>
                </div>
            </div>

            <div id='session-3'>
                <h4>
                    + 100 users around the world
                </h4>
            </div>

            <div id='session-4'>

                <div id='tutorial-header'>
                    <h4>
                        Tutorial
                    </h4>
                    <button onClick={() => setTutorialShow(!tutorialShow)}>
                        <img id={`arrow-icon-tutorial-show-${tutorialShow}`} src={ArrowIcon}/>
                    </button>
                </div>
                {
                    tutorialShow ?

                    <div id='container-tutorial'>
                        <div id='tutorial-title'>
                            <h2>Easy as a tool for non-professionals</h2>
                        </div>
                        <div id='tutorial-step-1'>
                            <img src={FirstStepTutorial}/>
                            <div id='tutorial-instruction'>
                                <h4>
                                    1 . Create a project...
                                </h4>
                            </div>
                        </div>
                        <div id='tutorial-step-2'>
                            <img src={SecondStepTutorial}/>
                            <div id='tutorial-instruction'>
                                <h4>
                                    2 . Go to management session...
                                </h4>
                            </div>
                        </div>
                        <div id='tutorial-step-3'>
                            <img src={ThirdStepTutorial}/>
                            <div id='tutorial-instruction'>
                                <h4>
                                    3 . Record a content and
                                    attach to the project...
                                </h4>
                            </div>
                        </div>
                        <div id='tutorial-step-4'>
                            <img src={FourthStepTutorial}/>
                            <div id='tutorial-instruction'>
                                <h4>
                                    4 . Make your content 
                                    useful...
                                </h4>
                            </div>
                        </div>
                        <div id='tutorial-step-5'>
                            <img src={FifthStepTutorial}/>
                            <div id='tutorial-instruction'>
                                <h4>
                                    5 . Mix the project contents
                                    selected and merge/close your first version.
                                </h4>
                            </div>
                        </div>
                    </div>

                    :

                    null
                }
            </div>
            <div id='session-5'>
                <div id='container-logo-button-sign-in'>
                    <img src={LogoRedWithTitleRed}/>
                    <button onClick={() => {navigate(REQUEST_REQUIREMENTS.SIGNIN_ENDPOINT)}}>
                        Sign In
                    </button>
                </div>
            </div>
            <div id='lading-page-bottom'>
                <h5>
                    Contact us
                </h5>
                <div id='container-contact'>
                    <img src={MailIcon}/>
                    <h6>jinner_software@protonmail.com</h6>
                </div>
                <div id='container-contact'>
                    <img src={PhoneIcon}/>
                    <h6>+55 47 9 8800 2867</h6>
                </div>
            </div>
        </div>
    )
}