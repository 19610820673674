import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../../../store';
import { useParams } from 'react-router-dom';
import { clearSelection, deselectProjectContent, selectAllProjectContents, selectProjectContent } from '../../../../features/sessions/projectsManagement/sessionManageProject';
import './projectContentsSelection.css';

export function ButtonSelectAllUseful(){
  const authHeaders = useSelector((state: RootState) => state.session.authHeaders);
  const dispatch = useDispatch<AppDispatch>();
  const [ selectAllClicked, setSelectAllClicked ] = useState(false);
  const { userNicknameRoute, projectIdRoute } = useParams();
  const projectContentSelectedList = useSelector((state: RootState) => state.sessionManageProjectContents.projectContentSelectedList);

  useEffect(()=>{
    if (projectContentSelectedList.length == 0){
        setSelectAllClicked(false)
    }
  },[projectContentSelectedList])

  async function handleSelectAll(){
    setSelectAllClicked(!selectAllClicked)
    if (!selectAllClicked && projectIdRoute){
      await dispatch(selectAllProjectContents({ authHeaders: authHeaders, userNickname: userNicknameRoute, projectId: parseInt(projectIdRoute) }));
    } else {
      dispatch(clearSelection());
      setSelectAllClicked(false);
    }
  }

  return(
    <div>
        <button style={!selectAllClicked || projectContentSelectedList.length == 0 ? {} : {
                                                                                            backgroundColor: 'rgb(0, 0, 0)',
                                                                                            borderRadius: '5px',
                                                                                            border: 'none',
                                                                                            justifyContent: 'center',
                                                                                            backgroundPosition: 'center',
                                                                                            backgroundSize: 'cover',
                                                                                            cursor: 'pointer',
                                                                                            boxShadow: '0 0px 0px rgba(0, 0, 0, 0.4)'
                                                                                            }} 
                className="button-select-all-useful-contents" 
                onClick={handleSelectAll}>
          Select All Useful
        </button>
      
    </div>
  )
}

export function ProjectContentsSelection({ projectContentObj }: any) {
  const authHeaders = useSelector((state: RootState) => state.session.authHeaders);
  const projectContentSelectedList = useSelector((state: RootState) => state.sessionManageProjectContents.projectContentSelectedList);
  const [isCurrentObjectSelected, 
         setIsCurrentObjectSelected] = useState(projectContentSelectedList.some((item) => {return item === projectContentObj.id}));
  const { userNicknameRoute } = useParams();
  const [contentSelectedBool, setContentSelectedBool] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const somethingSelected = useSelector((state: RootState) => state.sessionManageProjectContents.somethingSelected);

  useEffect(() => {
   
    const handleVerifySelectAll = () => {
      const selectionVerification = projectContentSelectedList.some(
        (item) => {return item === projectContentObj.id}
      );
      
      
      return selectionVerification
    }

    setIsCurrentObjectSelected(handleVerifySelectAll);

  }, [projectContentSelectedList]);
 
   
  const handleVerifySelection = () => {
    const selectionVerification = projectContentSelectedList.some(
      (item) => {return item === projectContentObj.id}
    );

    return selectionVerification
  }

  async function handleSelectProjectContent() {
    handleVerifySelection();


    if (isCurrentObjectSelected == false){
      const response = await dispatch(selectProjectContent({authHeaders: authHeaders, 
                                                            routeParams: {
                                                              userNickname: userNicknameRoute,
                                                              projectContentId: projectContentObj.id
                                                            }}));

      setIsCurrentObjectSelected(!handleVerifySelection());
    } else {
      dispatch(deselectProjectContent(projectContentObj.id));
      setIsCurrentObjectSelected(!handleVerifySelection());
      if (projectContentSelectedList.length == 1){
        dispatch(clearSelection());
      }
    }
  }

  return (
    <div>
      <label className={`checkbox-container ${(isCurrentObjectSelected) ? 'selected' : ''}`}>
        <input
          type='checkbox'
          className='checkbox-button-project'
          onChange={handleSelectProjectContent}
          checked={isCurrentObjectSelected}
        />
        <span className='checkmark'></span>
      </label>
    </div>
  );
}