import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { AppDispatch } from '../../store';
import { authenticateUserFromStorage } from '../sessions/sessionSlice';
import UserProfileButton from './UserProfileButton';
import SearchBar from './SeachBar';
import * as REQUEST_REQUIREMENTS from '../../api/requestRequirements';
import menuIcon from '../../assets/menu-icon-white.svg';
import jinnerLogo from '../../assets/logo-white-name-white-without-background-portrait.png';
import SearchIcon from '../../assets/search-icon-white.png'
import SideBar from '../../components/sideBar/SideBar';
import Notifications from './Notifications';
import { MessagesToolbar } from '../../components/toolbar/messages/MessagesToolbar';
import socketConn from '../../../socket'; 
import useSocketSetup from './useSocketSetup';
import './responsiveAppBar.css';

export default function ResponsiveAppBar (){
  const dropDownRef = useRef<HTMLDivElement | null>(null);
  const currentUser = useSelector((state: RootState) => state.session.currentUser);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const userValidated = useSelector((state: RootState) => state.session.loggedIn);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [socket] = useState(() => socketConn(2));
  const [PRIVATE_ROUTES,setPrivateRoutes]= useState<REQUEST_REQUIREMENTS.EndPoints>();
  const [menuButtonClicked, setMenuButtonClicked] = useState(false);
  const [optionClickedCloseSidebar,setOptionClickedCloseSidebar] = useState(false);
  const [searchInputQuery, setSearchInputQuery] = useState<string>('');
  const isMobile = window.matchMedia('(hover: none)').matches;
  const [searchClicked, setSearchClicked] = useState<boolean>(!isMobile);

  useSocketSetup(socket);
  
  useEffect(() => {

    const currentLocalization = location.pathname.split('/')[1] 
    
    if (currentLocalization != 'search'){
      setSearchInputQuery('')
    }
  }, [location]);

  useEffect(() => {
    async function authenticateUser() {
      if (!userValidated) {
        await dispatch(authenticateUserFromStorage());
      }
    }

    function handleClickOutside(event: MouseEvent) {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target as Node)) {
        setIsMenuOpen(false);
      }
    }

    if (currentUser){
      
      const routeParams = {
        userNickname: currentUser.nickname
      }

      const PRIVATE_ROUTES = REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: routeParams});

      setPrivateRoutes(PRIVATE_ROUTES);
    }

    authenticateUser();
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (userValidated) {
      setIsMenuOpen(false);
    }
  }, []);

  const handleSignIn = () => {
    navigate('/auth/sign_in');
  };

  function HandleGoHome(){
    if (PRIVATE_ROUTES && PRIVATE_ROUTES.USER){
      navigate(PRIVATE_ROUTES.USER)
    } else {
      navigate(REQUEST_REQUIREMENTS.SIGNIN_ENDPOINT)
    }
  }

  function handleSideBarCloseOpen(){
    setMenuButtonClicked(!menuButtonClicked);
  }

  return (
    <div>
      <div id="header-app-tool-bar">
        <div id="container-app-tool-bar">
          <div id="toolbar">
            <div id='logo-search-input'>
              {
                userValidated == true ?
                  <button id='menu-button' onClick={handleSideBarCloseOpen}>
                    <img src={menuIcon}/>
                  </button>
                :
                null
              }
              <div id='container-logo' onClick={HandleGoHome}>
                <img src={jinnerLogo} alt='jinner-logo-app-bar' />
              </div>

              {
                userValidated ?

                <div>
                  { (!searchClicked && isMobile) ?
                      
                      <div>
                        <button id='button-search' onClick={() => setSearchClicked(!searchClicked)}>
                          <img src={SearchIcon}/>
                        </button>
                      </div>
      
                      :
      
                      <SearchBar searchInputQuery={searchInputQuery} 
                      setSearchInputQuery={setSearchInputQuery}
                      setShowComponent={setSearchClicked}/>
                  
                  }
                </div>
                :

                null
              }
            </div>

            
            
            <div id='Container'>
              <div id='menu-container' ref={dropDownRef}>
              {(!searchClicked || !isMobile) && userValidated
              
              ?
              
              <div id='container-messages-notifications'>
                <div id='container-messages-component'>
                  <MessagesToolbar/>
                </div>
                <div id='container-notifications-component'>
                  <Notifications/>
                </div>
              </div>

              :
                
                null
              }
                <UserProfileButton
                  isMenuOpen={isMenuOpen}
                  setIsMenuOpen={setIsMenuOpen}
                  onSignIn={handleSignIn}
                />
              </div>
            </div>

          </div>
        </div>
      </div>
      {menuButtonClicked && userValidated == true ?
        <SideBar close={handleSideBarCloseOpen}/>
        :
        null
      }
    </div>
  );
}