import React from 'react';
import { PayloadAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { editContentInProject, getContentsInProject, 
         getProjects, postContentInProject, postProject, 
         deleteProjectContent, patchProject, postFileOnProject, 
         getProjectContentWithId,
         getContentsInProjectToMix,
         mixAudios} from '../../../api/sessionAPI';
import { Content, Instrument, Project } from '../../../#interfaces/interfaces';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

export interface ProjectContentsRouteParams {
  userNicknameRoute?: any;
  projectIdRoute?: any;
}

export interface PatchProjectContentData {
  authHeaders?: AuthHeaders;
  projectContentsRouteParams?: ProjectContentsRouteParams;
  patchProjectContentBody?: PatchProjectContentBody;
}

export interface ProjectRouteParams {
    userNicknameRoute?: any;
    projectIdRoute?: any;
  }

export interface ProjectContentRouteParams {
  userNicknameRoute?: any;
  projectContentId?: any;
}

export interface PostProjectContentData {
    authHeaders?: AuthHeaders;
    projectContentsRouteParams?: ProjectContentsRouteParams;
    postBody?: PostProjectContentBody;
} 

export interface GetProjectsRequestData{
    authHeaders: AuthHeaders;
    userNickname: string;
}

export interface GetProjectContentsData{
    authHeaders?: AuthHeaders;
    userNickname?: string;
    projectId?: number;
}

export interface PostProjectContentBody{
    id?: number;
}

export interface PatchProjectContentData {
    authHeaders?: AuthHeaders;
    projectContentsRouteParams?: ProjectContentsRouteParams;
    patchProjectContentBody?: PatchProjectContentBody;
}


export interface PatchCurrentProjectData {
    authHeaders?: AuthHeaders;
    projectRouteParams?: ProjectRouteParams;
    patchCurrentProjectBody?: PatchProjectBody;
}

export interface PostFileProjectBody {
  id?: number | string;
  url?: string;
}

export interface PostFileProjectData {
  authHeaders?: AuthHeaders;
  projectRouteParams?: ProjectRouteParams;
  postCurrentProjectFileBody?: PostFileProjectBody;
}

export interface DeleteProjectContentData {
    authHeaders?: AuthHeaders;
    projectContentRouteParams?: ProjectContentRouteParams;
}

export interface PatchProjectBody {
    id?: number;
    description?: string;
    privacity?: number;
    projectDuration?: number;
    projectSketchDuration?: number;
    title?: string;

    file?: string;
    startsIn?: number;
}

export interface PatchProjectContentBody {
  id?: number;
  useful?: boolean;
  startsIn?: number;
}

export interface CreateProjectData{
    authHeaders?: AuthHeaders;
    currentUser?: User; 
}

export interface AuthHeaders {
    accept?: string;
    accessToken?: string;
    client?: string;
    uid?: string;
  }

export interface User {
    email?: string;
    provider?: string;
    uid?: string;
    id?: number;
    allowPasswordChange?: boolean;
    name?: string;
    profileImage?: string;
    nickname?: string;
    role?: string;
  }

export interface AudioContent {
    id?: number;
    createdAt?: string;
    description?: string;
    title?: string;
    typeId?: number;
    updatedAt?: string;
    userId?: number;
    instrument?: Instrument;
}

export interface ProjectContent {
    content?: AudioContent;    
    contentId?: number;
    createdAt?: string;
    fileUrl?: string;
    id?: number;
    project: Project;
    projectId?: number;
    startsIn: number;
    updatedAt?: string;
    useFul?: boolean;
    volume?: number;
}

export interface ProjectAudioContent {
    content?: Content;
    id?: number;
    typeId?: number;
    contentId?: number;
    projectId?: number;
    startsIn?: number;
    volume?: number;
    createdAt?: string;
    updatedAt?: string;
    description?: string;
    fileUrl?: string;
    title?: string;
    useful?: string;
}

export interface ProjectAudioContents {
    audioContents?: ProjectAudioContent[];
}

export interface MasterFile {
  createdAt: string;
  signedUrl: string;
}


export interface GetProjectContentData {
  authHeaders?: AuthHeaders;
  routeParams: GetProjectContentUrlParams;
}

export interface GetProjectContentUrlParams{
  userNickname?: string;
  projectContentId?: string;
}

export interface GetProjectContentsToMixData {
  authHeaders?: AuthHeaders;
  userNickname?: string;
  projectId?: string;
  listProjectContentsId?: number[];
}

export interface MixContentsData {
  projectId?: number;
  idListToMix: number[];
}

interface ManageProjects {
    masterFiles: MasterFile[];
    currentProjectContents: ProjectAudioContents;
    projectContents: ProjectContent[];
    projectContentSelectedList: number[];
    errors: [],
    loading?: boolean;
    newUsefulProjectContents: ProjectContent[];
    newUselessProjectContents: ProjectContent[];
    uselessProjectContents: ProjectContent[];
    usefulProjectContents: ProjectContent[];
    projectContentsToMix: number[];
    currentProject: Project;
    somethingSelected: boolean;
    loadingScreenUseful: boolean;
    loadingScreenUseless: boolean;
    loadingSelection: boolean;
    listUsefulSelected: number[];
    listUselessSelected: number[];
    boolUsefulProjectContentSelected: boolean;
    boolUselessProjectContentSelected: boolean;
    pageLoadedOnceTime: boolean;
}
  
const initialState: ManageProjects = {
    masterFiles: [],
    currentProjectContents:{
        audioContents: []
    },
    currentProject: {
        project:{
          createdAt: undefined,
          description: undefined,
          id: undefined,
          nUsefulContents: undefined,
          nUselessContents: undefined,
          nVisits: undefined,
          privacity: undefined,
          projectSketchDuration: 0,
          projectDuration: 0,
          title: undefined,
          updatedAt: undefined,
          userId: undefined,
        },
        masterFiles: []
    },
    newUsefulProjectContents: [],
    newUselessProjectContents: [],
    projectContents: [],
    projectContentSelectedList: [],
    usefulProjectContents: [],
    uselessProjectContents: [],
    projectContentsToMix: [],
    loadingScreenUseful: false,
    loadingScreenUseless: false,
    loadingSelection: false,
    loading: false,
    somethingSelected: false,
    listUsefulSelected: [],
    boolUsefulProjectContentSelected: false,
    boolUselessProjectContentSelected: false,
    listUselessSelected: [],
    pageLoadedOnceTime: false,
    errors: []
};

export const getProjectContents = createAsyncThunk(
    'sessionProjectContents/getProjectContents',
    async (payload: GetProjectContentsData, {rejectWithValue}) => {
        const response = await getContentsInProject(
            payload.authHeaders,
            payload.userNickname,
            payload.projectId
        )

        if (response.status>=200 && response.status<300){ 
          return response.data
        } else {
          return rejectWithValue(response.data.data.errors)
        }
    }
)


export const refreshProjectContents = createAsyncThunk(
  'sessionProjectContents/refreshProjectContents',
  async (payload: GetProjectContentsData, {rejectWithValue}) => {
      const response = await getContentsInProject(
          payload.authHeaders,
          payload.userNickname,
          payload.projectId
      )

      if (response.status>=200 && response.status<300){ 
        return response.data
      } else {
        return rejectWithValue(response.data.data.errors)
      }
  }
)

export const patchProjectContentUsability = createAsyncThunk(
    'sessionProjectContents/patchProjectContentUsability',
    async (payload: PatchProjectContentData, {rejectWithValue}) => {

        const response = await editContentInProject(
            payload.authHeaders,
            payload.projectContentsRouteParams,
            payload.patchProjectContentBody
        );

        if (response.status>=200 && response.status<300){
            return response.data
        }
        else {
            return rejectWithValue(response.data)
        }
    }
)

export const postProjectContent = createAsyncThunk(
    'sessionProjectContents/postProjectContent',
    async (payload: PostProjectContentData, {rejectWithValue}) => {
        const response = await postContentInProject(
            payload.authHeaders,
            payload.projectContentsRouteParams,
            payload.postBody
        );

        if (response.status>=200 && response.status<300){
            return response.data
        } 
        else {
            return rejectWithValue(response.data)
        }
    }
)

export const takeProjectContentFromProject = createAsyncThunk(
    'sessionProjectContents/takeProjectContentFromProject',
    async (payload: DeleteProjectContentData, {rejectWithValue}) => {

        const response = await deleteProjectContent(
            payload.authHeaders,
            payload.projectContentRouteParams
        );

        if (response.status>=200 && response.status<300){
            return response.data
        } 
        else {
            return rejectWithValue(response.data)
        }
    }
)

export const patchCurrentProject = createAsyncThunk(
    'sessionProjectContents/patchCurrentProject',
    async (payload: PatchCurrentProjectData, {rejectWithValue}) => {
        const response = await patchProject(
            payload.authHeaders,
            payload.projectRouteParams,
            payload.patchCurrentProjectBody
        );

        if (response.status>=200 && response.status<300){
            return response.data
        } 
        else {
            return rejectWithValue(response.data)
        }
    }
)

export const postProjectFile = createAsyncThunk(
  'sessionProjectContents/postProjectFile',
  async (payload: PostFileProjectData, {rejectWithValue}) => {

      const response = await postFileOnProject(
          payload.authHeaders,
          payload.projectRouteParams,
          payload.postCurrentProjectFileBody
      );

      if (response.status>=200 && response.status<300){
          return response.data
      } 
      else {
          return rejectWithValue(response.data)
      }
  }
)

export const patchProjectContentStartsIn = createAsyncThunk(
  'sessionProjectContents/patchProjectContentStartsIn',
  async (payload: PatchProjectContentData, {rejectWithValue}) => {

      const response = await editContentInProject(
          payload.authHeaders,
          payload.projectContentsRouteParams,
          payload.patchProjectContentBody
      );

      if (response.status>=200 && response.status<300){
          return response.data
      }
      else {
          return rejectWithValue(response.data)
      }
  }
)

export const selectProjectContent = createAsyncThunk (
  'sessionProjectContents/selectProjectContent',
  async (payload: GetProjectContentData, {rejectWithValue}) => {
      const response = await getProjectContentWithId (
          payload.authHeaders,
          payload.routeParams
      );

      if (response.status>=200 && response.status<300){
          return response.data
      } else {
          return rejectWithValue(response)
      }
  }
)

export const selectAllProjectContents = createAsyncThunk (
  'sessionProjectContents/selectAllProjectContents',
  async (payload: GetProjectContentsData, {rejectWithValue}) => {
      const response = await getContentsInProject(
          payload.authHeaders,
          payload.userNickname,
          payload.projectId
      );

      if (response.status>=200 && response.status<300){
          return response.data
      } else {
          return rejectWithValue(response)
      }
  }
)

export const getProjectContentsToMix = createAsyncThunk (
  'sessionProjectContents/getProjectContentsToMix',
  async (payload: GetProjectContentsToMixData, {rejectWithValue}) => {
      const response = await getContentsInProjectToMix(
          payload.authHeaders,
          payload.userNickname,
          payload.projectId,
          payload.listProjectContentsId
      );

      if (response.status>=200 && response.status<300){
          return response.data
      } else {
          return rejectWithValue(response)
      }
  }
)

export const mixSelectedProjectContents = createAsyncThunk (
  'sessionProjectContents/mixSelectedProjectContents',
  async (payload: MixContentsData, {rejectWithValue}) => {
      const response = await mixAudios(
          payload.projectId,
          payload.idListToMix
      );

      if (response.status>=200 && response.status<300){
          return response.data
      } else {
          return rejectWithValue(response)
      }
  }
)

const projectContentsSlice = createSlice({
    name: 'sessionProjectContents',
    initialState,
    reducers: {
      loadingPage: (state) => {
        state.loading = true
      },
      stopLoadingPage: (state) => {
        state.loading = false
      },
      deselectProjectContent: ( state, action: PayloadAction<any> ) => {
        const idToRemove = action.payload;
        const newListItemRemoved = state.projectContentSelectedList.filter(item => item != idToRemove);
        state.projectContentSelectedList = newListItemRemoved;
        // state.projectContentsToMix = state.projectContentsToMix.filter(item => item != idToRemove);
        state.listUsefulSelected = state.listUsefulSelected.filter(item => item != idToRemove);
        state.listUselessSelected = state.listUselessSelected.filter(item => item != idToRemove);
        
        if (state.listUsefulSelected.length == 0) {
            state.boolUsefulProjectContentSelected = false;
        }

        if (state.listUselessSelected.length == 0) {
            state.boolUselessProjectContentSelected = false;
        }
      },
      clearSelection: ( state ) => {
          state.projectContentSelectedList = []; 
          state.somethingSelected = false;
          state.projectContentsToMix = [];
          state.errors = [];
          state.boolUsefulProjectContentSelected = false;
          state.boolUselessProjectContentSelected = false;
      }
    },
    extraReducers: (builder) => {
      builder
      .addCase(getProjectContents.pending, (state) => {
        state.masterFiles = [];
        state.loading = true;
        state.somethingSelected = false;
        state.projectContentSelectedList = [];
      })
      .addCase(getProjectContents.fulfilled, (state, action: any) => {

        if (action.payload["master_files"]!= undefined){ 
        state.masterFiles = convertKeysToCamelCase(action.payload["master_files"]).sort(
          (a: MasterFile, b: MasterFile) => {
            const dateA = new Date(a.createdAt);
            const dateB = new Date(b.createdAt);
        
            return dateB.getTime() - dateA.getTime();
          }
        );}
        state.currentProject.project = convertKeysToCamelCase(action.payload.project);
        state.usefulProjectContents = convertKeysToCamelCase(action.payload["useful_contents"]);
        state.uselessProjectContents = convertKeysToCamelCase(action.payload["useless_contents"]);
        state.newUsefulProjectContents = [];
        state.newUselessProjectContents = [];
        state.pageLoadedOnceTime = true;
        state.loading = false;
      })
      .addCase(getProjectContents.rejected, (state, action: any) => {
        state.errors = action.payload;
        state.loading = false;
        state.pageLoadedOnceTime = false;
      })
      .addCase(refreshProjectContents.pending, (state) => {
        state.masterFiles = [];
      })
      .addCase(refreshProjectContents.fulfilled, (state, action: any) => {

        if (action.payload["master_files"]!= undefined){ 
        state.masterFiles = convertKeysToCamelCase(action.payload["master_files"]).sort(
          (a: MasterFile, b: MasterFile) => {
            const dateA = new Date(a.createdAt);
            const dateB = new Date(b.createdAt);
        
            return dateB.getTime() - dateA.getTime();
          }
        );}
        state.currentProject.project = convertKeysToCamelCase(action.payload.project);
        state.usefulProjectContents = convertKeysToCamelCase(action.payload["useful_contents"]).sort((a: ProjectContent, b: ProjectContent) => {
          const dateA = a.updatedAt ? new Date(a.updatedAt) : new Date(0); // Data mínima se undefined
          const dateB = b.updatedAt ? new Date(b.updatedAt) : new Date(0); // Data mínima se undefined
          return dateB.getTime() - dateA.getTime();
        });
        state.uselessProjectContents = convertKeysToCamelCase(action.payload["useless_contents"]).sort((a: ProjectContent, b: ProjectContent) => {
          const dateA = a.updatedAt ? new Date(a.updatedAt) : new Date(0); // Data mínima se undefined
          const dateB = b.updatedAt ? new Date(b.updatedAt) : new Date(0); // Data mínima se undefined
          return dateB.getTime() - dateA.getTime();
        });
        state.pageLoadedOnceTime = true;
        
      })
      .addCase(refreshProjectContents.rejected, (state, action: any) => {
        state.errors = action.payload;
        state.pageLoadedOnceTime = false;

      })
      .addCase(postProjectContent.pending, (state) => {
        state.errors=[]
        state.loading = true
      })
      .addCase(postProjectContent.fulfilled, (state, action: any) => {
        const newProjectContentsConverted = convertKeysToCamelCase(action.payload)
        state.uselessProjectContents = [newProjectContentsConverted.projectContent,...state.uselessProjectContents]

        state.uselessProjectContents = state.uselessProjectContents.sort((a: ProjectContent, b: ProjectContent) => {
          const dateA = a.updatedAt ? new Date(a.updatedAt) : new Date(0); // Data mínima se undefined
          const dateB = b.updatedAt ? new Date(b.updatedAt) : new Date(0); // Data mínima se undefined
          return dateB.getTime() - dateA.getTime();
        });

        state.currentProject.project = convertKeysToCamelCase(action.payload.project)
        state.errors=[]
        state.loading = false
      })
      .addCase(postProjectContent.rejected, (state, action: any) => {
        state.errors = action.payload.errors
        state.loading = false
      })
      .addCase(takeProjectContentFromProject.pending, (state) => {
        state.loading = true
      })
      .addCase(takeProjectContentFromProject.fulfilled, (state, action: any) => {
        const projectContentIdToRemove = action.meta.arg.projectContentRouteParams.projectContentId;
        const newUselessProjectContents = state.newUselessProjectContents.filter(item => item.id != projectContentIdToRemove);
        const uselessProjectContents = state.uselessProjectContents.filter(item => item.id != projectContentIdToRemove);
        const newUsefulProjectContents = state.newUsefulProjectContents.filter(item => item.id != projectContentIdToRemove);
        const usefulProjectContents = state.usefulProjectContents.filter(item => item.id != projectContentIdToRemove);

        state.uselessProjectContents = uselessProjectContents.sort((a: ProjectContent, b: ProjectContent) => {
                                          const dateA = a.updatedAt ? new Date(a.updatedAt) : new Date(0);
                                          const dateB = b.updatedAt ? new Date(b.updatedAt) : new Date(0);
                                          return dateB.getTime() - dateA.getTime();
                                        });
        state.usefulProjectContents = usefulProjectContents.sort((a: ProjectContent, b: ProjectContent) => {
                                        const dateA = a.updatedAt ? new Date(a.updatedAt) : new Date(0);
                                        const dateB = b.updatedAt ? new Date(b.updatedAt) : new Date(0);
                                        return dateB.getTime() - dateA.getTime();
                                      });
        state.errors = [];
        state.loading = false;
      })
      .addCase(takeProjectContentFromProject.rejected, (state) => {
        state.loading = false
      })
      .addCase(patchProjectContentUsability.pending, (state) => {
          state.errors = [];
          state.loading = true;
      })
      .addCase(patchProjectContentUsability.fulfilled, (state, action: any) => {
        const projectContentIdToMoved = action.payload.id;
        const projectContentMoved = convertKeysToCamelCase(action.payload)
        if (action.payload.useful == false) {
          state.currentProject.project.projectDuration = projectContentMoved.project.projectDuration;
          state.currentProject.project.projectSketchDuration = projectContentMoved.project.projectSketchDuration;
          state.uselessProjectContents = [projectContentMoved, ...state.uselessProjectContents];
          const usefulProjectContents = state.usefulProjectContents.filter(item => item.id != projectContentIdToMoved);
      
          state.uselessProjectContents = state.uselessProjectContents.sort((a: ProjectContent, b: ProjectContent) => {
              const dateA = a.updatedAt ? new Date(a.updatedAt) : new Date(0);
              const dateB = b.updatedAt ? new Date(b.updatedAt) : new Date(0); 
              return dateB.getTime() - dateA.getTime();
          });
          state.usefulProjectContents = usefulProjectContents.sort((a: ProjectContent, b: ProjectContent) => {
              const dateA = a.updatedAt ? new Date(a.updatedAt) : new Date(0);
              const dateB = b.updatedAt ? new Date(b.updatedAt) : new Date(0);
              return dateB.getTime() - dateA.getTime();
          });
        } else {
            state.currentProject.project.projectDuration = projectContentMoved.project.projectDuration;
            state.currentProject.project.projectSketchDuration = projectContentMoved.project.projectSketchDuration;
            state.usefulProjectContents = [projectContentMoved, ...state.usefulProjectContents];
            const uselessProjectContents = state.uselessProjectContents.filter(item => item.id != projectContentIdToMoved);
        
            state.usefulProjectContents = state.usefulProjectContents.sort((a: ProjectContent, b: ProjectContent) => {
                const dateA = a.updatedAt ? new Date(a.updatedAt) : new Date(0);
                const dateB = b.updatedAt ? new Date(b.updatedAt) : new Date(0);
                return dateB.getTime() - dateA.getTime();
            });
            state.uselessProjectContents = uselessProjectContents.sort((a: ProjectContent, b: ProjectContent) => {
                const dateA = a.updatedAt ? new Date(a.updatedAt) : new Date(0);
                const dateB = b.updatedAt ? new Date(b.updatedAt) : new Date(0);
                return dateB.getTime() - dateA.getTime();
            });
        }
      
        state.loading = false;
      })
      .addCase(patchProjectContentUsability.rejected, (state, action: any) => {
        state.errors = action.payload;
        state.loading = false;
      })
      .addCase(patchCurrentProject.pending, (state) => {
        state.errors = []
      })
      .addCase(patchCurrentProject.fulfilled, (state, action: any) => {
        state.currentProject.project = convertKeysToCamelCase(action.payload)
        state.currentProject.project.title = action.payload.title
        state.currentProject.project.privacity = action.payload.privacity
      })
      .addCase(patchCurrentProject.rejected, (state, action: any) => {
        state.errors = action.payload.errors;
      })
      .addCase(postProjectFile.pending, (state) => {
        state.loading = true;
      })
      .addCase(postProjectFile.fulfilled, (state, action: any) => {
        state.masterFiles = convertKeysToCamelCase(action.payload["master_files"]).sort((a: MasterFile, b: MasterFile) => {
                                                                                          const dateA = new Date(a.createdAt);
                                                                                          const dateB = new Date(b.createdAt);
                                                                                      
                                                                                          return dateB.getTime() - dateA.getTime();
                                                                                        }
                                                                                      );
        state.currentProject.project = convertKeysToCamelCase(action.payload.project);

        state.usefulProjectContents = convertKeysToCamelCase(action.payload["useful_contents"]);
        state.uselessProjectContents = convertKeysToCamelCase(action.payload["useless_contents"]);
        state.newUsefulProjectContents = [];
        state.newUselessProjectContents = [];
        state.loading = false;
      })
      .addCase(postProjectFile.rejected, (state) => {
        state.loading = false;
      })
      .addCase(patchProjectContentStartsIn.pending, (state) => {
        state.errors = []
      })
      .addCase(patchProjectContentStartsIn.fulfilled, (state, action: any) => {
        const projectContentResponseConverted = convertKeysToCamelCase(action.payload)

        const foundInUsefulProjectContents = state.usefulProjectContents.find((object: ProjectContent) => object.id === projectContentResponseConverted.id);
        const foundInNewUsefulProjectContents = state.newUsefulProjectContents.find((object: ProjectContent) => object.id === projectContentResponseConverted.id);
        
        const foundInUselessProjectContents = state.uselessProjectContents.find((object: ProjectContent) => object.id === projectContentResponseConverted.id);
        const foundInNewUselessProjectContents = state.newUselessProjectContents.find((object: ProjectContent) => object.id === projectContentResponseConverted.id);


        if (projectContentResponseConverted.useful == true){
          if (foundInUsefulProjectContents) {
            const projectContentToEditIndex = state.usefulProjectContents.findIndex((object: ProjectContent) => object.id === projectContentResponseConverted.id);
            if (projectContentToEditIndex !== -1) {
              state.usefulProjectContents[projectContentToEditIndex].startsIn = projectContentResponseConverted.startsIn;
            }
          } else if (foundInNewUsefulProjectContents) {
            const projectContentToEditIndex = state.newUsefulProjectContents.findIndex((object: ProjectContent) => object.id === projectContentResponseConverted.id);
            if (projectContentToEditIndex !== -1) {

              state.newUsefulProjectContents[projectContentToEditIndex].startsIn = projectContentResponseConverted.startsIn;
            }
          }
        } else {
          if (foundInUselessProjectContents) {
            const projectContentToEditIndex = state.uselessProjectContents.findIndex((object: ProjectContent) => object.id === projectContentResponseConverted.id);
            if (projectContentToEditIndex !== -1) {
              state.uselessProjectContents[projectContentToEditIndex].startsIn = projectContentResponseConverted.startsIn;
            }
          if (foundInNewUselessProjectContents) {
            const projectContentToEditIndex = state.newUselessProjectContents.findIndex((object: ProjectContent) => object.id === projectContentResponseConverted.id);
            if (projectContentToEditIndex !== -1) {
              state.newUselessProjectContents[projectContentToEditIndex].startsIn = projectContentResponseConverted.startsIn;
            }
          }
        }}})
      .addCase(patchProjectContentStartsIn.rejected, (state) => {
          state.errors = []
      })
      .addCase(selectProjectContent.pending, (state, action: any) => {
          state.somethingSelected = false;
          state.loadingScreenUseful = true;
          state.loadingScreenUseless = true;
          state.loadingSelection = true;
      })
      .addCase(selectProjectContent.fulfilled, (state, action: any) => {
          state.somethingSelected = true;
          state.projectContentSelectedList = [convertKeysToCamelCase(action.payload.id), ...state.projectContentSelectedList];
          state.loadingScreenUseful = false;
          state.loadingScreenUseless = false;
          state.loadingSelection = false;

          if (action.payload.useful == true) {
              state.listUsefulSelected = [convertKeysToCamelCase(action.payload.id), ...state.listUsefulSelected];
              state.boolUsefulProjectContentSelected = true;
          }

          if (action.payload.useful == false) {
              state.listUselessSelected = [convertKeysToCamelCase(action.payload.id), ...state.listUselessSelected];
              state.boolUselessProjectContentSelected = true;
          }
      })
      .addCase(selectProjectContent.rejected, (state) => {
          state.somethingSelected = false;
          state.loadingScreenUseful = false;
          state.loadingScreenUseless = false;
          state.loadingSelection = false;
      })
      .addCase(selectAllProjectContents.pending, (state) => {
          state.somethingSelected = false;
          state.loadingScreenUseful = true;
          state.loadingScreenUseless = true;
          state.loadingSelection = true;
      })
      .addCase(selectAllProjectContents.fulfilled, (state, action: any) => {
          state.somethingSelected = true;
          const allProjectContents = convertKeysToCamelCase(action.payload)
          const allUsefulProjectContents = allProjectContents.usefulContents
          state.projectContentSelectedList = allUsefulProjectContents.map((item: ProjectContent) => item.id);
          state.listUsefulSelected = allUsefulProjectContents.map((item: ProjectContent) => item.id);
          state.boolUsefulProjectContentSelected = true;
          state.loadingScreenUseful = false;
          state.loadingScreenUseless = false;
          state.loadingSelection = false;
      })
      .addCase(selectAllProjectContents.rejected, (state, action: any) => {
          state.errors = action.payload;
          state.loadingScreenUseful = false;
          state.loadingScreenUseless = false;
          state.loadingSelection = false;
      })
      .addCase(mixSelectedProjectContents.pending, (state) => {
          state.loadingScreenUseful = true;
          state.loadingScreenUseless = true;
      })
      .addCase(mixSelectedProjectContents.fulfilled, (state, action: any) => {
          state.loadingScreenUseful = false;
          state.loadingScreenUseless = false;
      })
      .addCase(mixSelectedProjectContents.rejected, (state, action: any) => {
          state.loadingScreenUseful = false;
          state.loadingScreenUseless = false;
      })
      }
    })

export const {
      deselectProjectContent,
      clearSelection,
} = projectContentsSlice.actions;

export const manageProjectContentsSliceReducers = projectContentsSlice.reducer
export const manageProjectContentsSliceActions = projectContentsSlice.actions

export function convertKeysToCamelCase(obj: any): any {
    if (typeof obj === 'object' && obj !== null) {
      if (Array.isArray(obj)) {
        return obj.map(item => convertKeysToCamelCase(item));
      } else if (obj.constructor === Object) {
        const newObj: { [key: string]: any } = {};
        for (const key in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const camelCaseKey = key.replace(/_([a-z])/g, (_, letter) =>
              letter.toUpperCase()
            );
            newObj[camelCaseKey] = convertKeysToCamelCase(obj[key]);
          }
        }
        return newObj;
      }
    }
    return obj;
}

export function sortAndMergeGroups(...lists: any[][]): any[] {
  const mergedList = ([] as any[]).concat(...lists);

  const compareDates = (a: string, b: string) => {
      const dateA = new Date(a);
      const dateB = new Date(b);
      return dateB.getTime() - dateA.getTime();
  };

  mergedList.sort((a, b) => compareDates(b.createdAt, a.createdAt));

  const mergedListConverted = convertKeysToCamelCase(mergedList);
  return mergedListConverted;
}