import { useEffect, useState } from 'react';
import DefaultProfileImage  from '../../../../app/assets/profile-default-red.png';
import './usersSearch.css';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../app/store';
import { getSearchResults } from '../../../../app/features/sessions/sessionSearch/sliceSearch';
import { CircularProgress } from '@mui/material';
import { User, UserMusicalGenre } from '../../../../app/#interfaces/interfaces';
import { useNavigate } from 'react-router-dom';
import * as REQUEST_REQUIREMENTS from '../../../../../src/app/api/requestRequirements';

interface UserSearch {
    searchType: string;
    query: string | null;
  }

export default function UsersSearch({ searchType, query }: UserSearch){
    const dispatch = useDispatch<AppDispatch>();
    const [usersSearchPage,setUsersSearchPage] = useState(1);
    const authHeaders = useSelector((state: RootState) => state.session.authHeaders);
    const users = useSelector((state: RootState) => state.sessionSearch.users);
    const loadingMoreResults = useSelector((state: RootState) => state.sessionSearch.loadingMoreResults);
    const [isFetching, setIsFetching] = useState(false);
    const navigate = useNavigate();

    useEffect(()=>{

        async function fetchUsersData(){
            if (authHeaders && searchType && query && !isFetching){
                const response =  await dispatch(getSearchResults({headers: authHeaders,
                                                                   routeParams: {
                                                                        queryType: 'users',
                                                                        query: query,
                                                                        page: usersSearchPage
                                                                   }
                }))
                setIsFetching(false);
            }
        }
        fetchUsersData();
    },[])

    useEffect(() => {
        function handleScroll() {
            if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || isFetching) return;
            setUsersSearchPage(prevPage => prevPage + 1);
        }

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isFetching]);

    async function handleNavigationUserProfile( userTarget: User ){

        if (userTarget){
        const routeParams={
            userNickname: userTarget.userMoreInfo?.nickname
        }

        const PRIVATE_ROUTES = await REQUEST_REQUIREMENTS.handlePrivateRoutes({ROUTE_PARAMS: routeParams});

        return navigate(PRIVATE_ROUTES.USER)}
    }

    return(
        <>
        <div id='container-users-search'>
            <ul>
                {users.length > 0 && users.map((user: User, index: number) => (
                    <div id='container-li-search-user' key={index}>
                        <li key={index}>
                            <div id='container-top-li-search-user'>
                                <div id='container-user-info-li-search'>
                                    {
                                        user.userMoreInfo?.avatarUrl?

                                        <img src={user.userMoreInfo.avatarUrl}/>

                                        :

                                        <img src={DefaultProfileImage}/>
                                    }
                                    <a onClick={() => {handleNavigationUserProfile(user)}}>{user.userMoreInfo?.nickname}</a>
                                </div>
                            </div>
                            <div id='container-middle-li-search-user'>
                                <div id='container-about-user-search'>
                                    <pre>{user.userMoreInfo?.about}</pre>
                                </div>
                                <div id='container-location-user-search'>
                                    <span>{user.userMoreInfo?.city},&nbsp;</span>
                                    <span>{user.userMoreInfo?.state}&nbsp;-&nbsp;</span>
                                    <span>{user.userMoreInfo?.country}</span>
                                </div>
                            </div>
                            <div id='container-bottom-li-search-user'>
                                { user.userInstruments?.length && user.userInstruments?.length > 0 ?

                                <div id='container-instruments-user-search'>
                                    <h4>Instruments</h4>
                                        <ul>
                                            {(() => {
                                                let extraInstrumentsCount = 0;
                                                return (
                                                    user.userInstruments &&
                                                    user.userInstruments
                                                        .filter((userInstrument: any) => userInstrument.selected === true) 
                                                        .map((userInstrument: any, instrumentIndex: any) => {
                                                            if (instrumentIndex < 4) {
                                                                return (
                                                                    <li key={instrumentIndex}>
                                                                        <h6>{userInstrument.instrument.name}</h6>
                                                                    </li>
                                                                );
                                                            } else {
                                                                extraInstrumentsCount++;
                                                                if (user.userInstruments?.length && instrumentIndex === user.userInstruments?.length - 1) {
                                                                    return (
                                                                        <li key={instrumentIndex}>
                                                                            <h6>+ {extraInstrumentsCount}</h6>
                                                                        </li>
                                                                    );
                                                                }
                                                                return null;
                                                            }
                                                        })
                                                );
                                            })()}
                                        </ul>
                                </div>

                                :
                                null
                                }
                               {user.userInstruments?.length && user.userInstruments?.length > 0 ? (
                                <div id='container-genres-user-search'>
                                    <h4>Musical Genres</h4>
                                    <ul>
                                        {(() => {
                                            let extraGenresCount = 0;
                                            return (
                                                user.userMusicalGenres &&
                                                user.userMusicalGenres
                                                    .filter((userMusicalGenre: UserMusicalGenre) => userMusicalGenre.selected === true)
                                                    .map((userMusicalGenre: UserMusicalGenre, musicalGenreIndex: any) => {
                                                        if (musicalGenreIndex < 4) {
                                                            return (
                                                                <li key={musicalGenreIndex}>
                                                                    <h6>{userMusicalGenre.musicGenre.description}</h6>
                                                                </li>
                                                            );
                                                        } else {
                                                            extraGenresCount++;
                                                            if (user.userMusicalGenres?.length && musicalGenreIndex === user.userMusicalGenres.length - 1) {
                                                                return (
                                                                    <li key={musicalGenreIndex}>
                                                                        <h6>+ {extraGenresCount}</h6>
                                                                    </li>
                                                                );
                                                            }
                                                            return null;
                                                        }
                                                    })
                                            );
                                        })()}
                                    </ul>
                                </div>
                            ) : null}
                            </div>
                        </li>
                    </div>
                    
                ))}
            </ul>
            <div id = 'container-nothing-to-show-users-search'>
                { users.length == 0 && !loadingMoreResults ?
                    <h2>Nothing to show</h2>
                    :
                    null    
                }
            </div>
            {loadingMoreResults == true
                                
                ?
                    <div id='container-loading-spineer'>
                        <CircularProgress color='secondary' sx={{ color: 'brown' }}/>
                    </div>
                
                :

                null
            }
        </div>
        </>
    )
}