import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from './features/counter/counterSlice';
import { sessionAuthSliceReducer } from "./features/sessions/sessionSlice";
import { userContentsReducer } from './features/sessions/sessionAudioContent/sliceUserContents';
import { manageProjectsSliceReducers } from './features/sessions/projectsManagement/sessionManageProjects';
import { manageProjectPlayerSliceReducers } from './features/sessions/sessionProjectManagementPlayer/sliceProjectManagementPlayer';
import { manageProjectContentsSliceReducers } from './features/sessions/projectsManagement/sessionManageProject';
import { profileSliceReducers } from './features/sessions/sessionProfileNavigation';
import { userConfigSliceReducers } from './features/sessions/sessionConfig/sessionUserConfigurations';
import { groupsSliceReducers } from './features/sessions/sessionGroups/sessionGroups';
import { groupSliceReducers } from './features/sessions/sessionGroups/sessionGroup';
import { postProjectSliceReducers } from './features/sessions/sessionPostProject/sessionPostProject';
import { searchSliceReducers } from './features/sessions/sessionSearch/sliceSearch';
import { notificationsSliceReducers } from './features/sessions/sessionNotification';
import { dialoguesSliceReducers } from './features/sessions/sessionDialogues';

export const store = configureStore({
            reducer: {
                      counter: counterReducer,
                      session: sessionAuthSliceReducer,
                      sessionUserContents: userContentsReducer,
                      sessionManageProjects: manageProjectsSliceReducers,
                      sessionManageProjectContents: manageProjectContentsSliceReducers,
                      sessionProjectPlayerManagement: manageProjectPlayerSliceReducers,
                      sessionSearch: searchSliceReducers,
                      sessionProfile: profileSliceReducers,
                      sessionUserConfig: userConfigSliceReducers,
                      sessionGroups: groupsSliceReducers,
                      sessionGroup: groupSliceReducers,
                      sessionProjectPosts: postProjectSliceReducers,
                      sessionNotifications: notificationsSliceReducers,
                      sessionDialogues: dialoguesSliceReducers

            }
});

type GetStateType = typeof store.getState
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<GetStateType>;
export type AppThunk<ReturnType = void> = ThunkAction<
                                                      ReturnType,
                                                      RootState,
                                                      unknown,
                                                      Action<string>
                                                    >;