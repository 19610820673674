import React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useWavesurfer } from "./useWavesurfer"
import { formatDuration } from "./Duration"
import { ProjectContentsSelection } from "./ProjectContentsSelection";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store";
import { useParams } from "react-router-dom";
import { ProjectContent, getProjectContents, patchProjectContentStartsIn, 
         patchProjectContentUsability } from "../../../../features/sessions/projectsManagement/sessionManageProject";
import playIcon from '../../../../assets/play-icon.png';
import pauseIcon from '../../../../assets/pause-icon.png';
import mergeIcon from '../../../../assets/merge-icon.png';
import divideIcon from '../../../../assets/divide-icon.png';
import rejectIcon from '../../../../assets/reject.png';
import './waveSurferPlayer.css';
import { toast } from 'react-toastify';
import IconComment from '../../../../assets/comment-icon-white.png';
import IconInfo from '../../../../assets/info-icon-white.png';
import { SkeletonLoading } from '../../../skeletonLoading/SkeletonLoading';

export const WaveSurferPlayer = (props: any) => {
    const containerRef = useRef<any>();
    const containerRefMemorized = useMemo(() => containerRef, [containerRef]);
    const wavesurfer = useWavesurfer(containerRefMemorized, props);
    const dispatch = useDispatch<AppDispatch>();
    const { userNicknameRoute, projectIdRoute } = useParams();
    const projectContent =  props.projectContentObj;
    const projectContentUseful =  props.projectContentObj.useful;
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const authHeaders = useSelector((state: RootState) => state.session.authHeaders);
    const containerWaveWidth = props.projectContentObj.content.file.blob.metadata.centiseconds;
    const containerProjectSketchWidth = useSelector((state: RootState) => state.sessionManageProjectContents.currentProject.project.projectSketchDuration);
    const containerProjectWidth = useSelector((state: RootState) => state.sessionManageProjectContents.currentProject.project.projectDuration);
    const [startsInCentiInt,setStartsInCentiInt] = useState(Math.abs(props.projectContentObj.startsIn));
    const [startsInFormated,setStartsInFormated] = useState(formatDuration(startsInCentiInt));
    const [endsIn, setEndsIn] = useState((props.projectContentObj.startsIn + duration));
    const [endsInFormated, setEndsInFormated] = useState(formatDuration(endsIn));
    const [seekingStartsIn, setSeekingStartsIn] = useState<boolean>(false);
    const [position, setPosition] = useState<number>(0);
    const currentUser = useSelector((state: RootState) => state.session.currentUser);
    const currentProject = useSelector((state : RootState) => state.sessionManageProjectContents.currentProject);
    const content = useSelector((state: RootState) => props.projectContentObj.content);
    const currentUserIsProjectOwner = (currentUser && currentUser.id === currentProject.project.userId);
    const currentUserIsContentOwner = (currentUser?.id == content.userId);
    const containerProjectContentOpacity = useSelector((state: RootState) => {
      const currentProjectContentSelected = state.sessionManageProjectContents.projectContentSelectedList.some(
        (item) => {return (item === projectContent.id) }
      );

      if ((currentProjectContentSelected || state.sessionManageProjectContents.projectContentSelectedList.length == 0 ) || state.sessionManageProjectContents.pageLoadedOnceTime == true){
        return 1
      } else {
        return 0.3
      }
    });
    const projectContentSelectedList = useSelector((state: RootState) => state.sessionManageProjectContents.projectContentSelectedList);
    const onPlayClick = useCallback(() => {
      wavesurfer?.isPlaying() ? wavesurfer?.pause() : wavesurfer?.play()
    }, [wavesurfer])

    useEffect(() => {
      
      containerRefMemorized.current.style.width = "100%";
      if (!wavesurfer) return
  
      setCurrentTime(0)
      setIsPlaying(false)

      setDuration(Math.round(wavesurfer.getDuration() * 100));
      const subscriptions = [
        wavesurfer.on('play', () => setIsPlaying(true)),
        wavesurfer.on('pause', () => setIsPlaying(false)),
        wavesurfer.on('timeupdate', (currentTime) => {
          const currentTimeCentiseconds = Math.round(currentTime * 100);
          setCurrentTime(currentTimeCentiseconds)})
      ]

      wavesurfer.on('ready', () => {
        setDuration(Math.round(wavesurfer.getDuration()) * 100 );
        setStartsInCentiInt(Math.round(startsInCentiInt))
        setStartsInFormated(formatDuration(startsInCentiInt))
        setEndsIn((Math.round(wavesurfer.getDuration() * 100) + startsInCentiInt));
        setEndsInFormated(formatDuration(Math.round(Math.round(wavesurfer.getDuration() * 100) + startsInCentiInt)))});
  
      return () => {
        subscriptions.forEach((unsub) => unsub())
      }

    }, [wavesurfer,startsInCentiInt]);

    const handleSeekChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (seekingStartsIn) {
        const newValue = parseFloat(e.target.value);
        setPosition(newValue);
      }
    };
    
    useEffect(() => {
      if (!seekingStartsIn) {
        setPosition(position);
      }
    }, [position, seekingStartsIn]);

    async function handleSeekMouseDown(event: React.MouseEvent<HTMLInputElement>){
      const newValueStartsInCentIn = Math.floor(parseFloat(event.currentTarget.value));
      setStartsInCentiInt(newValueStartsInCentIn);
      setSeekingStartsIn(true);
    }
  
    async function handleSeekMouseUp(event: React.MouseEvent<HTMLInputElement>){
      const newValueStartsInCentIn = Math.floor(parseFloat(event.currentTarget.value));

      const response = await dispatch(patchProjectContentStartsIn({authHeaders: authHeaders,
                                                           projectContentsRouteParams:{
                                                                                      userNicknameRoute: userNicknameRoute,
                                                                                      projectIdRoute: projectIdRoute
                                                           },
                                                           patchProjectContentBody: {id: projectContent.id,
                                                                                     startsIn: newValueStartsInCentIn}
                                                          }))
      setStartsInCentiInt(newValueStartsInCentIn);
      setSeekingStartsIn(true);
    }

    async function handleTouchUp(event: React.TouchEvent<HTMLInputElement>) {
      const newValueStartsInCentIn = Math.floor(parseFloat(event.currentTarget.value));
      const response = await dispatch(patchProjectContentStartsIn({authHeaders: authHeaders,
                                                           projectContentsRouteParams: {
                                                                                        userNicknameRoute: userNicknameRoute,
                                                                                        projectIdRoute: projectIdRoute
                                                            },
                                                           patchProjectContentBody: {
                                                                                      id: projectContent.id,
                                                                                      startsIn: newValueStartsInCentIn
                                                            }
                                                          }))

      setStartsInCentiInt(newValueStartsInCentIn);
      setSeekingStartsIn(true);
    }

    const handleSeekChangePosition = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = Math.round(parseFloat(event.target.value));
      setStartsInCentiInt(newValue)
      setStartsInFormated(formatDuration(newValue))
      setEndsIn(Math.round(newValue) + duration)
      setEndsInFormated(formatDuration(endsIn))
    };

    const handleStarsInField = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = parseFloat(e.target.value);
      setStartsInCentiInt(newValue*10)
      setStartsInFormated(formatDuration(newValue*10))
    };
  
    useEffect(() => {
      if (!seekingStartsIn) {
        setStartsInCentiInt(startsInCentiInt);
      }
    }, [startsInCentiInt, seekingStartsIn]);

    async function handleMoveToUseful(){
      const response = await dispatch(patchProjectContentUsability({authHeaders: authHeaders,
                                                    projectContentsRouteParams:{
                                                                                userNicknameRoute: userNicknameRoute,
                                                                                projectIdRoute: projectIdRoute
                                                    },
                                                    patchProjectContentBody: {id: projectContent.id,
                                                                              useful: true
                                                    }
      }))

      if (response.meta.requestStatus && response.meta.requestStatus != 'fulfilled'){
        response.payload.errors.map((item: string) => {toast.error(item)})
      } else if (projectIdRoute) {
        await dispatch(getProjectContents({authHeaders: authHeaders, userNickname: userNicknameRoute, projectId: parseInt(projectIdRoute)}))
      }

    }

    async function handleMoveToUseless(){
      await dispatch(patchProjectContentUsability({authHeaders: authHeaders,
                                                   projectContentsRouteParams:{
                                                                               userNicknameRoute: userNicknameRoute,
                                                                               projectIdRoute: projectIdRoute
                                                   },
                                                   patchProjectContentBody: {id: projectContent.id,
                                                                             useful: false}}))
    }

  //   async function handleRemoveFromProject(projectToDeleteId: string){
      
  //     await dispatch(takeProjectContentFromProject({authHeaders: authHeaders,
  //                                          projectContentRouteParams: {
  //                                                                       userNicknameRoute: userNicknameRoute,
  //                                                                       projectContentId: projectToDeleteId
  //                                                                     }
  //                                         }
  //     ))
  // }

    // if (loadingScreenUseless || loadingScreenUseful) {
    //   return <SkeletonLoading />;
    // }

    return (
      <>
        
        {/* { openYesNoRemoveContentModal ? 
            <YesNoDialogModal message={'Do you wanna <span id="text-remove-content-from-project">remove</span> this content from the project? This action cannot be undone.'}
                              onYesClick={handleRemoveFromProject}
                              onCloseClick={setOpenYesNoRemoveContentModal}/>
          :
            null
        }  */}

      {projectContentUseful === false ?

          <div className="container-wave-surfer-project" style={{ 
                                                                display: "relative", 
                                                                width: `${containerProjectSketchWidth}px`, 
                                                                opacity: `${containerProjectContentOpacity}`
                                                          }}>
            <div className="container-controller-box">
              <div className="container-checkBox-play-button">
                <div className="checkBox-container">
                  <ProjectContentsSelection 
                    projectContentObj={props.projectContentObj}
                  />
                </div>
                {isPlaying ? <img className="play-pause-button" onClick={onPlayClick} src={pauseIcon}/> 
                            : <img className="play-pause-button" onClick={onPlayClick} src={playIcon}/>}
              </div>
      
              
              <div className="container-time-progress">
                  <div className="container-time-from-start">
                      <h5>{formatDuration(currentTime)}</h5>
                  </div>
                  <div className="container-time-to-end">
                      <h5>-{formatDuration(duration - currentTime)}</h5>
                  </div>
              </div>
            </div>
            <div className="container-wave-slider" style={{width: `${containerProjectSketchWidth/10}px`}}>
              <div className="container-wave" style={{ width: `${containerWaveWidth/10}px`, transform: `translateX(${startsInCentiInt/10}px)` }}>
                <div className="wave-surfer" style={{ display: "relative"}} ref={containerRefMemorized}/>
              </div>
              {currentUserIsContentOwner || projectContentUseful || currentUserIsProjectOwner ?
                <div className="container-slider-bar" style={{ width: `100%`}}>
                  <input className="wave-slider"
                          id="container-wave-position"
                          type="range"
                          min={0}
                          step={0.0001}
                          max={((containerProjectSketchWidth-containerWaveWidth))}
                          onChange={handleSeekChangePosition}
                          value={startsInCentiInt}
                          onMouseDown={handleSeekMouseDown}
                          onMouseUp={handleSeekMouseUp}
                          onTouchEnd={handleTouchUp}
                          style={{width:`100%`}}
                          />
                </div>
                :
                <div className="container-slider-bar" style={{ width: `100%`}}>
                  <input className="wave-slider"
                          id="container-wave-position"
                          type="range"
                          min={0}
                          step={0.0001}
                          max={((containerProjectSketchWidth-containerWaveWidth))}
                          onChange={handleSeekChangePosition}
                          value={startsInCentiInt}
                          onMouseDown={handleSeekMouseDown}
                          onMouseUp={handleSeekMouseUp}
                          onTouchEnd={handleTouchUp}
                          style={{width:`width: 100%`}}
                          disabled
                          />
                </div>
            
              }
              
            </div>
            <div className='container-show-startIn-endsIn'>
              <label className="label-starts-in">Starts In</label>
              <div className="container-starts-in-formated">{startsInFormated}</div>
              <label className="label-starts-in">Ends In</label>
              <div className="container-starts-in-formated">{endsInFormated}</div>
            </div>

            {projectContentSelectedList.length == 0 
            
              ? 
              
              <div id='container-change-usability-comments-info'>
                <div id='container-change-usability-useless-contents'>
                  { currentUserIsProjectOwner && projectContentUseful == false ?
                    <button id='change-usabilty-button'
                    onClick={handleMoveToUseful}>
                      <img src={mergeIcon}/>
                    </button> : null }
                  { currentUserIsProjectOwner && projectContentUseful == true ?
                    <button id='change-usabilty-button'
                      onClick={handleMoveToUseless}>
                      <img src={divideIcon}/>
                    </button> : null }
                  { currentUserIsContentOwner || currentUserIsProjectOwner ?
                    <button id='change-usabilty-button'
                      onClick={() => {props.handleSetProjectIdToRemove(props.projectContentObj.id);
                                      props.handleShowYesNoModal(true);}}>
                      <img src={rejectIcon} style={{ transform: 'rotate(-90deg) scaleY(-1)' }}/>
                    </button> : null }
                  </div>
                  <div id='container-button-comment-info'>
                    <button id='button-comment-project-content'
                            onClick={() => {props.handleShowToggleBoxComments(!props.boxCommentsOpened);
                                            props.setProjectContentToComment(props.projectContentObj.id);
                                           }
                                    }>
                      <img src={IconComment} />
                    </button>
                    <button id='button-project-content-more-info'
                            onClick={() => {props.handleSetShowProjectContenInfoModal(true);
                                            props.handleSetIdToShowInfo(props.projectContentObj.id);
                            }
                            }>
                      <img src={IconInfo}/>
                    </button>
                  </div>
                </div>
              :
              null
            }
        </div> 
        
        :

          <div className="container-wave-surfer-project" style={{ 
                                                                  display: "relative", 
                                                                  width: `${containerProjectSketchWidth}px`, 
                                                                  opacity: `${containerProjectContentOpacity}`
                                                              }}>
            <div className="container-controller-box">
              <div className="container-checkBox-play-button">
                <div className="checkBox-container">
                  <ProjectContentsSelection 
                    projectContentObj={props.projectContentObj}
                  />
                </div>
                {isPlaying ? <img className="play-pause-button" onClick={onPlayClick} src={pauseIcon}/> 
                            : <img className="play-pause-button" onClick={onPlayClick} src={playIcon}/>}
              </div>
      
              
              <div className="container-time-progress">
                  <div className="container-time-from-start">
                    <h5>{formatDuration(currentTime)}</h5> 
                  </div>
                  <div className="container-time-to-end">
                    <h5>-{formatDuration(duration - currentTime)}</h5> 
                  </div>
              </div>
            </div>
            <div className="container-wave-slider" style={{width: `${containerProjectWidth/10}px`}}>
              <div className="container-wave" style={{ width: `${containerWaveWidth/10}px`, transform: `translateX(${startsInCentiInt/10}px)` }}>
                <div className="wave-surfer" style={{ display: "relative"}} ref={containerRefMemorized}/>
              </div>
              {currentUserIsContentOwner || !projectContentUseful || currentUserIsProjectOwner ?
                <div className="container-slider-bar" style={{ width: `100%`}}>
                  <input className="wave-slider"
                          type="range"
                          min={0}
                          step={0.0001}
                          max={((containerProjectWidth-containerWaveWidth))}
                          onChange={handleSeekChangePosition}
                          value={startsInCentiInt}
                          onMouseDown={handleSeekMouseDown}
                          onMouseUp={handleSeekMouseUp}
                          onTouchEnd={handleTouchUp}
                          style={{width:`100%`}}
                          />
                </div>
                :
                <div className="container-slider-bar" style={{ width: `100%`}}>
                  <input className="wave-slider"
                          type="range"
                          min={0}
                          step={0.0001}
                          max={((containerProjectWidth-containerWaveWidth))}
                          onChange={handleSeekChangePosition}
                          value={startsInCentiInt}
                          onMouseDown={handleSeekMouseDown}
                          onMouseUp={handleSeekMouseUp}
                          onTouchEnd={handleTouchUp} 
                          style={{width:`100%`, height:`100%`}}
                          disabled
                          />
                </div>
              }
              
            </div>
              <div className='container-show-startIn-endsIn'>
                <label className="label-starts-in">Starts In</label>
                <div className="container-starts-in-formated">{startsInFormated}</div>
                <label className="label-starts-in">Ends In</label>
                <div className="container-starts-in-formated">{endsInFormated}</div>
              </div>
              {
                projectContentSelectedList.length == 0 ?
                <div id='container-change-usability-comments-info'>
                  <div id='container-change-usability-useless-contents'>
                    { currentUserIsProjectOwner && projectContentUseful == false ?
                      <button id='change-usabilty-button'
                      onClick={handleMoveToUseful}>
                        <img src={mergeIcon}/>
                      </button> : null }
                    { currentUserIsProjectOwner && projectContentUseful == true ?
                      <button id='change-usabilty-button'
                              onClick={handleMoveToUseless}>
                        <img src={divideIcon}/>
                      </button> : null }
                    { currentUserIsContentOwner || currentUserIsProjectOwner ?
                      <button id='change-usabilty-button'
                              onClick={() => {props.handleSetProjectIdToRemove(props.projectContentObj.id);
                                              props.handleShowYesNoModal(true)}}>
                        <img src={rejectIcon} style={{ transform: 'rotate(-90deg) scaleY(-1)' }}/>
                      </button> : null }
                  </div>
                  <div>
                    <button id='button-comment-project-content'
                            onClick={() => {props.handleShowToggleBoxComments(!props.boxCommentsOpened);
                                            props.setProjectContentToComment(props.projectContentObj.id);
                                           }
                                    }>
                      <img src={IconComment} />
                    </button>
                    <button id='button-project-content-more-info'
                            onClick={() => {props.handleSetShowProjectContenInfoModal(true);
                                            props.handleSetIdToShowInfo(props.projectContentObj.id);
                            }
                            }>
                      <img src={IconInfo}/>
                    </button>
                  </div>
                </div>
              : null
                    }
          </div>
      }
      </>
      
    )
  }