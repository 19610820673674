import { useEffect, useRef, useState } from 'react';
import MessagesIcon from '../../../assets/message-icon-white.png';
import './messagestoolbar.css';
import { getUserDialogues, selectDialogue } from '../../../features/sessions/sessionDialogues';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store';
import { Dialogue, DialogueMember } from '../../../#interfaces/interfaces';
import DefaultProfileImage from '../../../assets/profile-default-red.png';
import { CircularProgress } from '@mui/material';

export function MessagesToolbar() {
    const containerDialoguesToolbarRef = useRef<HTMLDivElement>(null);
    const [oppenedToggleDialogues, setOppenedToggleDialogues] = useState(false);
    const currentUser = useSelector((state: RootState) => state.session.currentUser);
    const authHeaders = useSelector((state: RootState) => state.session.authHeaders);
    const userDialogues = useSelector((state: RootState) => {
        const userDialogues = state.sessionDialogues.userDialogues;
    
        return [...userDialogues].sort((a, b) => {
            const lastMessageA = a.dialogueMessages?.at(-1)?.createdAt || 0;
            const lastMessageB = b.dialogueMessages?.at(-1)?.createdAt || 0;
            return new Date(lastMessageB).getTime() - new Date(lastMessageA).getTime();
        });
    });
    const selectedDialogues = useSelector((state: RootState) => state.sessionDialogues.selectedDialogues);
    const [dialoguesPage, setDialoguesPage] = useState<number>(1);
    const dispatch = useDispatch<AppDispatch>();
    const loadingMoreDialogues = useSelector((state: RootState) => state.sessionDialogues.loadingMoreDialogueMessages);
    const containerDialoguesRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (!authHeaders) return;
    
        const fetchUserDialogues = async () => {
            try {
                await dispatch(getUserDialogues({
                    authHeaders: authHeaders,
                    urlParams: {
                        page: dialoguesPage
                    }
                }));
            } catch (error) {
                console.error('Failed to fetch user dialogues:', error);
            }
        };
    
        fetchUserDialogues();
    }, [dialoguesPage]);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                containerDialoguesToolbarRef.current &&
                !containerDialoguesToolbarRef.current.contains(event.target as Node)
            ) {
                setOppenedToggleDialogues(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    async function handleSelectDialogue(userDialogue: string) {
        setOppenedToggleDialogues(!oppenedToggleDialogues)
        try {
            
            if (!selectedDialogues.includes(userDialogue)) {
                return dispatch(selectDialogue({ nickname: userDialogue }));
            } else {
                return
            }

        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (!oppenedToggleDialogues) return;
    
        const container = containerDialoguesRef.current;
    
        if (!container) return;
    
        const handleScroll = () => {
            const { scrollTop, clientHeight, scrollHeight } = container;
    
            if (scrollTop + clientHeight >= scrollHeight && !loadingMoreDialogues) {
                setDialoguesPage((prevPage) => prevPage + 1);
            }
        };
    
        container.addEventListener("scroll", handleScroll);
    
        return () => {
            container.removeEventListener("scroll", handleScroll);
        };
    }, [oppenedToggleDialogues, loadingMoreDialogues]);
    

    return (
        <div id='container-messages-toolbar' ref={containerDialoguesToolbarRef}>
            <button id='button-open-close-toggle-messages' onClick={() => setOppenedToggleDialogues(!oppenedToggleDialogues)}>
                <img src={MessagesIcon} alt="Messages Icon" />
            </button>

            {oppenedToggleDialogues && (
                <div id="container-toggle-dialogues" ref={containerDialoguesRef}>
                    <ul id='ul-dialogues'>
                        {userDialogues.map((userDialogue: Dialogue) => (
                            <li key={userDialogue.id} onClick={() => {
                                const otherMemberNickname = userDialogue.dialogueMembers?.find(
                                    (dialogueMember: DialogueMember) => dialogueMember.user?.nickname !== currentUser?.nickname
                                )?.user?.nickname;
                            
                                if (otherMemberNickname){
                                    handleSelectDialogue(otherMemberNickname);
                                }
                            }}>
                                {userDialogue.dialogueMembers?.filter((dialogueMember: DialogueMember) => dialogueMember.user?.nickname !== currentUser?.nickname)
                                    .map((dialogueMember: DialogueMember) => (
                                        <div key={dialogueMember.user?.id} className="dialogue-member">
                                            <img src={dialogueMember.user?.userMoreInfo?.avatarUrl || DefaultProfileImage} alt="Avatar" />
                                            <div id='container-dialogue-members-nickname-message'>
                                                <p id='p-dialogue-member-nickname'>{dialogueMember.user?.nickname}</p>
                                            </div>
                                        </div>
                                    ))
                                }

                                
                                {userDialogue.dialogueMessages && userDialogue.dialogueMessages[0].message ?
                                    <div id='container-message'>
                                        <p>{userDialogue.dialogueMessages[userDialogue.dialogueMessages.length - 1].message}</p>
                                    </div>

                                    :

                                    null
                                }
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}